import { Button } from "@mui/material";
import React from "react";
import { saveAsCSV } from "../../../utils/fileSaver";
import { Box } from "@mui/system";
import { STypographyTitle } from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";

export default function ProductComponent() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getProductsCsv } = useZekay();

  const handleProducts = async () => {
    setIsLoading(true);

    getProductsCsv()
      .then((data: string | undefined) => {
        data !== undefined && saveAsCSV(data, "products");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box sx={{ width: "100%", textAlign: "start" }}>
      <STypographyTitle padding={2}>Produits</STypographyTitle>
      {!isLoading ? (
        <Button
          variant="contained"
          sx={{ margin: "10px", width: "100%", height: "40px" }}
          onClick={handleProducts}
        >
          Exporter les produits (CSV)
        </Button>
      ) : (
        <Button
          variant="contained"
          disabled
          sx={{ margin: "10px", width: "100%", height: "40px" }}
        >
          Exporter les produits (CSV)
        </Button>
      )}
    </Box>
  );
}
