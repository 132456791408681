import { Dispatch, SetStateAction } from "react";
import { SClose } from "../../styles/StyledMUI";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

interface PdfComponentProps {
  file: string | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function PdfComponent(props: PdfComponentProps) {
  return (
    <Box
      sx={{
        position: "fixed",
        height: "calc(100vh - 64px)",
        width: "100%",
        top: 64,
        left: 0,
        zIndex: 1,
        backgroundColor: "rgba(255, 255, 255, 1)",
      }}
    >
      <Grid container justifyContent="flex-end" sx={{ padding: 1 }}>
        <SClose onClick={() => props.setIsOpen(false)} />
      </Grid>
      <Grid container item justifyContent="center" sx={{ height: "100%" }}>
        <iframe
          src={props.file}
          style={{
            width: "90%",
            height: "90%",
            border: "none",
          }}
        ></iframe>
      </Grid>
    </Box>
  );
}
