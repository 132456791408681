import React from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Grid,
  Chip,
  Menu,
  MenuItem,
  IconButton,
  Divider,
} from "@mui/material";
import useZekay from "../../../../contexts/controllers/useZekay";
import {
  SAddCriteria,
  SAddOption,
  SAvatar,
  SSave,
  STypographyItem,
  STypographyTitle,
} from "../../../../styles/StyledMUI";
import { useAlert } from "../../../../hooks/useAlert";

interface GroupsMapping {
  [key: string]: string[];
}

const GroupsComponent: React.FC = () => {
  const {
    getGoogleLabels,
    getQualificationsList,
    getNewsletterMapping,
    updateNewsletterMapping,
  } = useZekay();
  const [qualificationsList, setQualificationsList] = React.useState<
    string[] | undefined
  >([]);
  const [newsletterMapping, setNewsletterMapping] =
    React.useState<GroupsMapping>({});
  const [availableLabels, setAvailableLabels] = React.useState<
    string[] | undefined
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [labelAnchorEl, setLabelAnchorEl] = React.useState<null | HTMLElement>(
    null
  ); // Pour le menu des labels
  const [currentLabel, setCurrentLabel] = React.useState<string | null>(null); // Label sélectionné pour ajout des qualifications
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const { showAlert } = useAlert();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const labels = await getGoogleLabels();
        const qualifications = await getQualificationsList();
        const mapping = await getNewsletterMapping();

        setQualificationsList(qualifications);
        setNewsletterMapping(mapping || {});

        const mappedLabels = Object.keys(mapping || {});
        const nonMappedLabels = labels?.filter(
          (label) => !mappedLabels.includes(label)
        );
        setAvailableLabels(nonMappedLabels);

        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des données");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const handleMappingChange = (label: string, qualification: string) => {
    setNewsletterMapping((prevMapping) => {
      // Vérifier si la qualification existe déjà avant de l'ajouter
      if (prevMapping[label] && prevMapping[label].includes(qualification)) {
        return prevMapping; // Ne rien faire si la qualification est déjà présente
      }
      return {
        ...prevMapping,
        [label]: prevMapping[label]
          ? [...prevMapping[label], qualification]
          : [qualification],
      };
    });
    setAnchorEl(null); // Ferme le menu après ajout
  };

  const handleRemoveQualification = (label: string, qualification: string) => {
    setNewsletterMapping((prevMapping) => ({
      ...prevMapping,
      [label]: prevMapping[label].filter((q) => q !== qualification),
    }));
  };

  const handleSave = () => {
    if (newsletterMapping) {
      updateNewsletterMapping(newsletterMapping)
        .then(() =>
          showAlert("Configuration mise à jour avec succès", "success")
        )
        .catch((error) => {
          if (error instanceof Error) {
            showAlert(error.message, "error");
          }
        });
    }
  };

  const getAvailableQualifications = (label: string) => {
    const mappedQualifications = newsletterMapping[label] || [];
    return qualificationsList?.filter((q) => !mappedQualifications.includes(q));
  };

  // Ouvrir le menu des qualifications pour un label spécifique
  const handleAddQualificationClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    label: string
  ) => {
    setCurrentLabel(label);
    setAnchorEl(event.currentTarget);
  };

  // Ouvrir le menu des labels
  const handleLabelMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLabelAnchorEl(event.currentTarget);
  };

  const handleAddLabel = (label: string) => {
    setNewsletterMapping((prevMapping) => ({
      ...prevMapping,
      [label]: [],
    }));
    setAvailableLabels((prevAvailable) =>
      prevAvailable?.filter((l) => l !== label)
    );
    setLabelAnchorEl(null); // Fermer le menu des labels après sélection
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setLabelAnchorEl(null);
  };

  return (
    <Box sx={{ width: "100%", padding: 3 }}>
      <Box sx={{ marginBottom: 3 }}>
        <STypographyTitle>Groupes</STypographyTitle>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />
      <Grid container spacing={3}>
        {Object.keys(newsletterMapping).map((label, index) => (
          <Grid item xs={12} key={index} marginLeft={4}>
            <STypographyItem variant="h6">{"Groupe " + label}</STypographyItem>

            {/* Liste des qualifications déjà mappées, affichées comme des tags (Chips) */}
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems={"center"}
              gap={1}
              mb={2}
            >
              {newsletterMapping[label] &&
                newsletterMapping[label].map((qualification, idx) => (
                  <Chip
                    key={idx}
                    label={qualification}
                    onDelete={() =>
                      handleRemoveQualification(label, qualification)
                    }
                    variant="outlined"
                  />
                ))}

              {/* Bouton "+" pour ajouter des qualifications */}
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(event) => handleAddQualificationClick(event, label)}
              >
                <SAddOption />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Menu pour sélectionner une qualification à ajouter */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {currentLabel &&
          getAvailableQualifications(currentLabel)?.map(
            (qualification, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMappingChange(currentLabel, qualification)}
              >
                {qualification}
              </MenuItem>
            )
          )}
      </Menu>

      {/* Bouton et menu pour ajouter des labels */}
      <Box sx={{ margin: 2 }}>
        <IconButton
          aria-controls="label-menu"
          aria-haspopup="true"
          onClick={handleLabelMenuClick}
        >
          <SAddCriteria />
        </IconButton>
        <Menu
          id="label-menu"
          anchorEl={labelAnchorEl}
          keepMounted
          open={Boolean(labelAnchorEl)}
          onClose={handleMenuClose}
        >
          {availableLabels?.map((label, index) => (
            <MenuItem key={index} onClick={() => handleAddLabel(label)}>
              Ajouter {label}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box sx={{ margin: 2 }}>
        <SAvatar onClick={handleSave}>
          <SSave />
        </SAvatar>
      </Box>
    </Box>
  );
};

export default GroupsComponent;
