import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import useZekay from "../../../../contexts/controllers/useZekay";
import {
  Module,
  TrainingTemplate,
  CourseTemplate,
} from "../../../../api/zekay/Zekay.typed";
import ModuleForm from "./ModuleForm";
import {
  SAddModule,
  SSave,
  STypographyItem,
} from "../../../../styles/StyledMUI";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function CourseTemplateEditor() {
  const [templates, setTemplates] = React.useState<TrainingTemplate[]>();
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<TrainingTemplate>();
  const [selectedSession, setSelectedSession] =
    React.useState<CourseTemplate>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { getTrainingsTemplates, getCourseTemplate, updateCourseTemplate } =
    useZekay();

  // Charger les templates lors du montage du composant
  React.useEffect(() => {
    setIsLoading(true);
    getTrainingsTemplates()
      .then((t) => setTemplates(t || []))
      .finally(() => setIsLoading(false));
  }, [getTrainingsTemplates]);

  // Charger la session sélectionnée
  const handleChangeTemplate = async (e: SelectChangeEvent<string>) => {
    const templateId = e.target.value;
    const template = templates?.find(
      (t) => t.CreditID.toString() === templateId
    );

    if (template) {
      setSelectedTemplate(template);
      setIsLoading(true);
      try {
        const session = await getCourseTemplate(template.CreditID);
        setSelectedSession(session);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Modifier les modules à l'intérieur de la session
  const handleModuleChange = (index: number, updatedModule: Module) => {
    if (selectedSession) {
      const updatedModules = [...selectedSession.course.modules];
      updatedModules[index] = updatedModule;
      setSelectedSession({
        ...selectedSession,
        course: {
          ...selectedSession.course,
          modules: updatedModules,
        },
      });
    }
  };

  // Supprimer un module
  const handleDeleteModule = (index: number) => {
    if (selectedSession) {
      const updatedModules = [...selectedSession.course.modules];
      updatedModules.splice(index, 1); // Supprimer le module
      setSelectedSession({
        ...selectedSession,
        course: {
          ...selectedSession.course,
          modules: updatedModules,
        },
      });
    }
  };

  // Ajouter un nouveau module
  const addModule = () => {
    if (selectedSession) {
      setSelectedSession({
        ...selectedSession,
        course: {
          ...selectedSession.course,
          modules: [
            ...selectedSession.course.modules,
            { name: "", criteria: [] },
          ],
        },
      });
    }
  };

  // Gérer le déplacement des modules
  const onDragEnd = (result: any) => {
    if (!result.destination || !selectedSession) return;

    const updatedModules = Array.from(selectedSession.course.modules);
    const [movedModule] = updatedModules.splice(result.source.index, 1);
    updatedModules.splice(result.destination.index, 0, movedModule);

    setSelectedSession({
      ...selectedSession,
      course: {
        ...selectedSession.course,
        modules: updatedModules,
      },
    });
  };

  const handleSaveSession = () => {
    if (selectedSession) {
      updateCourseTemplate(selectedSession);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <FormControl sx={{ minWidth: "400px" }}>
          <InputLabel>Formation</InputLabel>
          <Select
            value={selectedTemplate?.CreditID.toString() || ""}
            label="Formation"
            onChange={handleChangeTemplate}
            sx={{ fontSize: "15px" }}
          >
            {templates?.map((template) => (
              <MenuItem
                key={template.CreditID}
                value={template.CreditID.toString()}
              >
                {template.Agency + " - " + template.Qualification}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isLoading && <CircularProgress />}
      </Box>
      <Divider />

      {selectedSession ? (
        <Box sx={{ marginTop: "20px", width: "100%" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="modules">
              {(provided) => (
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {selectedSession.course.modules.map((module, index) => (
                    <Draggable
                      key={index}
                      draggableId={`module-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ModuleForm
                            key={index}
                            module={module}
                            onChange={(updatedModule) =>
                              handleModuleChange(index, updatedModule)
                            }
                            onDeleteModule={() => handleDeleteModule(index)} // Passer la fonction de suppression
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>

          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              width: "10%",
              height: "60px",
              backgroundColor: "#E0E0E0",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px 0 0 0",
            }}
          >
            <Tooltip title="Ajouter module">
              <IconButton sx={{ marginRight: 2 }} onClick={() => addModule()}>
                <SAddModule />
              </IconButton>
            </Tooltip>
            <IconButton sx={{ marginRight: 2 }} onClick={handleSaveSession}>
              <SSave />
            </IconButton>
          </Box>
        </Box>
      ) : selectedTemplate !== undefined && selectedSession === undefined ? (
        <STypographyItem sx={{ textAlign: "center", marginTop: "20px" }}>
          Aucun modèle pour cette formation
        </STypographyItem>
      ) : (
        <STypographyItem sx={{ textAlign: "center", marginTop: "20px" }}>
          Sélectionnez un modèle pour charger la session correspondante.
        </STypographyItem>
      )}
    </Box>
  );
}
