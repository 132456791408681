import React, { Dispatch, SetStateAction } from "react";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  ZekayCustomer,
  Training,
  Session,
} from "../../../api/zekay/Zekay.typed";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useZekay from "../../../contexts/controllers/useZekay";
import SessionsList from "../sessions/SessionsList";
import useAuth from "../../../contexts/controllers/useAuth";
import { SAnalytics, SAvatar, SCreate, SDone } from "../../../styles/StyledMUI";
import TrainingSummaryModal from "./TrainingSummaryModal";

type TrainingItemProps = {
  training: Training;
  customer: ZekayCustomer | undefined;
  setCustomerUpdated: Dispatch<SetStateAction<ZekayCustomer | undefined>>;
  setTrainingsFormOpen: Dispatch<SetStateAction<boolean>>;
};

export default function TrainingItem(props: TrainingItemProps) {
  const { getCustomer, createSession } = useZekay();
  const { user } = useAuth();
  const [openCourseList, setOpenCourseList] = React.useState<boolean>(false);
  const [summaryOpen, setSummaryOpen] = React.useState<boolean>(false);

  const handleCreateSession = () => {
    if (props.customer && props.training) {
      const s: Session = {
        CustomerId: props.customer.Id,
        CreditId: props.training.CreditId,
        Date: new Date().toISOString().split("T")[0],
        Instructor: user?.Name,
        Form: undefined,
      };

      createSession(s).then(() => {
        getCustomer(String(props.customer?.Id))
          .then((c) => props.setCustomerUpdated(c))
          .then(() => setOpenCourseList(true));
      });
    }
  };

  const handleSessionSaved = () => {
    if (props.customer) {
      getCustomer(String(props.customer.Id)).then((c) =>
        props.setCustomerUpdated(c)
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: "1em", border: "none" }}>
          <IconButton
            onClick={() => {
              getCustomer(String(props.customer?.Id)).then((c) =>
                props.setCustomerUpdated(c)
              );
              setOpenCourseList(!openCourseList);
            }}
          >
            {openCourseList ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: "20em", border: "none" }} padding={"none"}>
          {props.training.Agency}
        </TableCell>
        <TableCell sx={{ width: "150em", border: "none" }} padding={"none"}>
          {props.training.Qualification}
        </TableCell>
        {props.training.Sessions !== undefined ? (
          <TableCell sx={{ width: "1em", border: "none" }}>
            <Tooltip title="Voir synthèse">
              <SAvatar>
                <SAnalytics onClick={() => setSummaryOpen(true)} />
              </SAvatar>
            </Tooltip>
          </TableCell>
        ) : null}
        <TableCell sx={{ width: "1em", border: "none" }}>
          {props.training.Status === "En cours" ? (
            <SAvatar>
              <SCreate onClick={handleCreateSession}></SCreate>
            </SAvatar>
          ) : (
            <SDone />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openCourseList} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} style={{ padding: 0, border: "none" }}>
                    <SessionsList
                      training={props.training}
                      onSessionSave={handleSessionSaved}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <TrainingSummaryModal
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        training={props.training}
        setTrainings={function (
          value: React.SetStateAction<Training[] | undefined>
        ): void {
          throw new Error("Function not implemented.");
        }}
        refreshTrainings={function (): Promise<Training[] | undefined> {
          throw new Error("Function not implemented.");
        }}
      />
    </React.Fragment>
  );
}
