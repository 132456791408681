import { Button, Grid } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { saveAsCSV } from "../../../utils/fileSaver";
import { Box } from "@mui/system";
import { STypographyTitle } from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";
import { InvoicesDate } from "../../../api/zekay/Zekay.typed";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAlert } from "../../../hooks/useAlert";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";

export default function InvoicesComponent() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const { getInvoicesCsv } = useZekay();
  const { showAlert } = useAlert();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const handleInvoices = async () => {
    if (startDate !== undefined && endDate !== undefined) {
      setIsLoading(true);

      const period: InvoicesDate = {
        Start: startDate.toISOString(),
        End: endDate.toISOString(),
      };

      getInvoicesCsv(period)
        .then((data: string | undefined) => {
          data !== undefined && saveAsCSV(data, "invoices");
        })
        .finally(() => setIsLoading(false));
    } else {
      showAlert("Veuillez sélectionner une date de début et de fin", "error");
    }
  };

  return (
    <Box sx={{ width: "100%", textAlign: "start" }}>
      <STypographyTitle padding={2}>Factures</STypographyTitle>

      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      {!isLoading ? (
        <Button
          variant="contained"
          sx={{ margin: "10px", width: "40%", height: "40px" }}
          onClick={handleInvoices}
        >
          Exporter les factures (CSV)
        </Button>
      ) : (
        <Button
          variant="contained"
          disabled
          sx={{ margin: "10px", width: "40%", height: "40px" }}
        >
          Exporter les factures (CSV)
        </Button>
      )}
    </Box>
  );
}

interface DatePickerRangeProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  setStartDate: Dispatch<SetStateAction<Date | undefined>>;
  setEndDate: Dispatch<SetStateAction<Date | undefined>>;
}

const DateRangePicker = (props: DatePickerRangeProps) => {
  return (
    <Grid container sx={{ width: "40%", height: "auto", margin: "20px" }}>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <DatePicker
            disableFuture
            label="Début"
            format="DD/MM/YYYY"
            onChange={(e: any) => props.setStartDate(e)}
            timezone="UTC"
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <DatePicker
            disableFuture
            format="DD/MM/YYYY"
            label="Fin"
            onChange={(e: any) => props.setEndDate(e)}
            timezone="UTC"
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};
