import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/system";
import { SClose, STypography } from "../../../../styles/StyledMUI";
import useAuth from "../../../../contexts/controllers/useAuth";
import { LoginForm } from "../../../../api/auth/Auth.typed";
import { useAlert } from "../../../../hooks/useAlert";

type UserInviteComponentProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function UserInviteComponent(props: UserInviteComponentProps) {
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [role, setRole] = React.useState<number>(1);
  const { showAlert } = useAlert();
  const { inviteRequest } = useAuth();

  const handleInvite = async () => {
    const f: LoginForm = {
      Email: email,
      Name: name,
      Role: role,
    };

    inviteRequest(f).catch((err) => showAlert(err.message, "error"));
    props.setIsOpen(false);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 64,
        left: 0,
        width: "100vw",
        height: "calc(100vh - 64px)",
        zIndex: "199",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "calc(90% - 50px)",
          overflow: "auto",
          borderRadius: "10px",
          padding: "1rem",
          zIndex: "200",
          backgroundColor: "white",
          textAlign: "start",
        }}
      >
        <Grid container>
          <Grid container item justifyContent="flex-end">
            <SClose onClick={() => props.setIsOpen(false)} />
          </Grid>
          <Grid item xs={12}>
            <STypography sx={{ marginBottom: "20px" }}>
              Veuillez entrer les informations suivantes pour inviter un
              utilisateur :
            </STypography>
            <TextField
              label="Email"
              variant="standard"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
              value={email}
            />
            <TextField
              label="Prénom"
              variant="standard"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <FormControl sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel>Role</InputLabel>
              <Select
                value={role}
                label="Role"
                onChange={(e) => setRole(e.target.value as number)}
              >
                <MenuItem value={1}>Administrateur</MenuItem>
                <MenuItem value={2}>Moniteur</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center" }}>
              {email !== "" && name !== "" ? (
                <Button
                  variant="contained"
                  sx={{ margin: "10px", width: "50%", height: "40px" }}
                  onClick={handleInvite}
                >
                  Soumettre
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ margin: "10px", width: "50%", height: "40px" }}
                  disabled
                >
                  Soumettre
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
