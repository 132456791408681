import {
  Avatar,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import {
  SAddDocument,
  SAvatar,
  SDone,
  SMissing,
} from "../../../styles/StyledMUI";
import React, { Dispatch, SetStateAction, useRef } from "react";
import { base64ToBlob, convertBase64 } from "../../../utils/fileSaver";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { Description } from "@mui/icons-material";

type FileWithPDFComponentProps = {
  file: any;
  setFile: Dispatch<
    SetStateAction<string | Blob | File | ProgressEvent<FileReader> | undefined>
  >;
  title: string | undefined;
  pdf: string | undefined;
};

const FileWithPDFComponent: React.FC<FileWithPDFComponentProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await convertBase64(file);
      props.setFile(base64);
    }
    setIsLoading(false);
  };

  const viewFile = () => {
    if (props.file) {
      const fileBlob = base64ToBlob(props.file);
      const fileURL = URL.createObjectURL(fileBlob);
      const pdfWindow = window.open("");
      pdfWindow?.document.write(
        `<iframe width='100%' height='100%' src='${fileURL}'></iframe>`
      );
    }
  };

  const handleIconButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          flex: 1,
        }}
      >
        <Link
          to={props.pdf || ""}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <IconButton
            sx={{
              bgcolor: "primary.main",
              width: 56,
              height: 56,
              margin: 1,
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            <Avatar
              sx={{
                bgcolor: "transparent",
                width: 24,
                height: 24,
              }}
            >
              <Description sx={{ color: "white" }} />
            </Avatar>
          </IconButton>
          <Typography fontSize={"12px"}>Document original</Typography>
          <Typography fontSize={"12px"}>
            (à télécharger, remplir et redéposer dans la zone de droite)
          </Typography>
        </Link>
      </Box>
      <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        {!isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box display="flex" alignItems="center">
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                onChange={uploadFile}
                accept="application/pdf"
              />
              <Tooltip title="Ajouter document">
                <IconButton
                  onClick={handleIconButtonClick}
                  sx={{
                    bgcolor: "primary.main",
                    width: 56,
                    height: 56,
                    margin: 1,
                    "&:hover": {
                      bgcolor: "primary.dark",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "transparent",
                      width: 24,
                      height: 24,
                    }}
                  >
                    <SAddDocument />
                  </Avatar>
                </IconButton>
              </Tooltip>
              {props.file ? (
                <IconButton
                  onClick={viewFile}
                  sx={{
                    bgcolor: "primary.main",
                    width: 56,
                    height: 56,
                    margin: 1,
                    "&:hover": {
                      bgcolor: "primary.dark",
                    },
                  }}
                >
                  <SAvatar
                    sx={{
                      bgcolor: "transparent",
                      width: 24,
                      height: 24,
                    }}
                  >
                    <VisibilityIcon sx={{ color: "white" }} />
                  </SAvatar>
                </IconButton>
              ) : null}
            </Box>
            {props.file ? (
              <Box display="flex" alignItems="center">
                <SDone />
                <Typography fontSize={"13px"}>Document renseigné</Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <SMissing />
                <Typography fontSize={"13px"}>
                  Document non renseigné
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};

export default FileWithPDFComponent;
