import { Dispatch, SetStateAction } from "react";
import { ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import {
  SClose,
  SMissing,
  STypography,
  STypographyTitle,
} from "../../../styles/StyledMUI";
import { Box, Divider, Grid } from "@mui/material";
import PhotoComponent from "./display/PhotoComponent";

type ProfileFormProps = {
  customer: ZekayCustomer | undefined;
  setProfileFormOpen: Dispatch<SetStateAction<boolean>>;
  setCustomerUpdated: Dispatch<SetStateAction<ZekayCustomer | undefined>>;
};

export default function ProfileForm(props: ProfileFormProps) {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 64,
        left: 0,
        width: "100vw",
        height: "calc(100vh - 64px)",
        zIndex: "199",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          height: "40%",
          overflow: "auto",
          borderRadius: "10px",
          padding: "1rem",
          zIndex: "200",
          backgroundColor: "white",
          textAlign: "start",
        }}
      >
        <Grid container>
          <Grid container item justifyContent="flex-end">
            <SClose onClick={() => props.setProfileFormOpen(false)} />
          </Grid>
          <Grid item xs={10}>
            <STypographyTitle>
              {props.customer?.FirstName + " " + props.customer?.LastName}
            </STypographyTitle>
            {props.customer?.MailAddress !== undefined ? (
              <STypography>{props.customer?.MailAddress}</STypography>
            ) : (
              <Box display="flex" alignItems="center">
                <SMissing />
                <STypography>Email non renseigné</STypography>
              </Box>
            )}
            {props.customer?.Street !== undefined ||
            props.customer?.PostalCode !== undefined ||
            props.customer?.City !== undefined ? (
              <STypography>
                {props.customer?.Street +
                  " " +
                  props.customer?.PostalCode +
                  " " +
                  props.customer?.City}
              </STypography>
            ) : (
              <Box display="flex" alignItems="center">
                <SMissing />
                <STypography>Adresse non renseignée</STypography>
              </Box>
            )}
            {props.customer?.Birthdate !== undefined ? (
              <STypography>{props.customer?.Birthdate}</STypography>
            ) : (
              <Box display="flex" alignItems="center">
                <SMissing />
                <STypography>Date de naissance non renseignée</STypography>
              </Box>
            )}
            {props.customer?.Phonenumber !== undefined ? (
              <STypography>{props.customer?.Phonenumber}</STypography>
            ) : (
              <Box display="flex" alignItems="center">
                <SMissing />
                <STypography>Téléphone non renseigné</STypography>
              </Box>
            )}

            <Divider variant="middle" sx={{ margin: 2, width: "50%" }} />
            <STypography>
              Niveau : {props.customer?.LastQualification}
            </STypography>
            <Divider variant="middle" sx={{ margin: 2, width: "50%" }} />
            <STypography>ID : {props.customer?.Id}</STypography>
            <Divider variant="middle" sx={{ margin: 2, width: "50%" }} />
          </Grid>
          <Grid item xs={2}>
            <PhotoComponent
              customer={props.customer}
              setCustomerUpdated={props.setCustomerUpdated}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
