import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Tooltip,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { File, Training, ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import { SAvatar, SClose, SGenerate } from "../../../styles/StyledMUI";
import useAuth from "../../../contexts/controllers/useAuth";
import React, { Dispatch, SetStateAction } from "react";
import useZekay from "../../../contexts/controllers/useZekay";
import { convertBase64 } from "../../../utils/fileSaver";
import { useAlert } from "../../../hooks/useAlert";
import { useLocation } from "react-router-dom";

type RefreshFunction<T> = () => Promise<T>;
type RefreshFunctionWithId<T> = (id?: string) => Promise<T>;

type RefreshTrainingFunction =
  | RefreshFunction<Training[] | undefined>
  | RefreshFunctionWithId<Training[] | undefined>;

type TrainingSummaryModalProps = {
  open: boolean;
  onClose: () => void;
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
  refreshTrainings: RefreshTrainingFunction;
};

type CriteriaSummary = {
  description: string;
  status: string;
  comments: Array<{ sessionNumber: number; comment: string }>;
  isComplete: boolean;
};

function generateModuleSummary(training: Training) {
  const moduleSummaries: Record<string, CriteriaSummary[]> = {};

  training.Sessions?.forEach((session) => {
    session.Form?.modules?.forEach((module) => {
      if (!moduleSummaries[module.name]) {
        moduleSummaries[module.name] = [];
      }

      module.criteria?.forEach((criterion) => {
        const existingSummary = moduleSummaries[module.name].find(
          (c) => c.description === criterion.description
        );

        const selectedStatuses = criterion.status || [];
        const comments = criterion.comments_value || "";

        const allSelected = selectedStatuses.length > 0;
        const isComplete =
          allSelected && selectedStatuses.length === criterion.options.length;

        const status = allSelected ? selectedStatuses.join(", ") : "Non rempli";

        if (existingSummary) {
          // Si un commentaire est déjà présent, vérifier s'il est unique
          const lastComment =
            existingSummary.comments[existingSummary.comments.length - 1];
          if (comments && (!lastComment || lastComment.comment !== comments)) {
            existingSummary.comments.push({
              sessionNumber: session.Number,
              comment: comments,
            });
          }
          // Mettre à jour le statut et l'état d'achèvement
          existingSummary.status = status;
          if (isComplete) {
            existingSummary.isComplete = true;
          }
        } else {
          // Créer un nouveau résumé pour le critère
          const newSummary: CriteriaSummary = {
            description: criterion.description,
            status: status,
            comments: comments
              ? [{ sessionNumber: session.Number, comment: comments }]
              : [],
            isComplete: isComplete,
          };
          moduleSummaries[module.name].push(newSummary);
        }
      });
    });
  });

  return moduleSummaries;
}

export default function TrainingSummaryModal({
  open,
  onClose,
  training,
  setTrainings,
  refreshTrainings,
}: TrainingSummaryModalProps) {
  const moduleSummaries = generateModuleSummary(training);
  const [customer, setCustomer] = React.useState<ZekayCustomer>();
  const { user, isAdmin } = useAuth();
  const { getCustomer, updateTrainingFile } = useZekay();
  const { showAlert } = useAlert();
  const location = useLocation();

  React.useEffect(() => {
    getCustomer(String(training.CustomerID)).then((c) => setCustomer(c));
  }, []);

  const uploadSheetFile = async (file: any) => {
    const base64 = await convertBase64(file);

    const f: File = {
      CustomerID: training.CustomerID,
      CreditID: training.CreditId,
      Type: "sheet",
      File: base64,
    };

    try {
      await updateTrainingFile(f)
        .then(() => refreshTrainings())
        .then((ts) => {
          setTrainings(ts);
        });
    } catch (error) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      }
    }
  };

  const getColorForCriterion = (criterion) => {
    if (criterion.status !== undefined) {
      if (criterion.status === "OK" || criterion.isComplete) {
        return "green"; // All criteria are met
      } else if (criterion.status === "A retravailler") {
        return "orange";
      } // Needs to be reworked or incomplete
      else if (criterion.status !== "Non rempli" && !criterion.isComplete) {
        return "orange"; // Needs to be reworked or incomplete
      } else if (criterion.status === "Non rempli") {
        return "white"; // Not filled or empty
      }
    } else {
      return "white"; // Default case, fallback to white
    }
  };

  const handleGeneratePdf = async () => {
    if (customer !== undefined) {
      const doc = new jsPDF();
      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;
      const margin = 14; // Marge à gauche
      let cursorY = 35; // Position de départ sous l'en-tête

      // Load the image
      const logoUrl = require("../../../styles/images/escapade_logo.jpeg"); // Use require to load the image

      const getBase64Image = (imgUrl) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "Anonymous";
          img.src = imgUrl;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
          };
          img.onerror = (error) => reject(error);
        });
      };

      const logoBase64 = await getBase64Image(logoUrl);

      // En-tête
      const header = () => {
        doc.setFontSize(10);
        doc.text(`Nom: ${customer.LastName} ${customer.FirstName}`, margin, 20);
        doc.text(`Formation: ${training.Qualification}`, margin, 25);
        doc.setDrawColor(0);
        doc.setLineWidth(0.1);
        // Add the logo in the top right corner
        doc.addImage(logoBase64, "JPEG", pageWidth - 60, 10, 50, 30); // Adjust the position and size as needed
      };

      // Ajouter l'en-tête sur la première page
      header();

      doc.setFontSize(14);
      cursorY += 5;

      const instructorColumn = [
        "Séances",
        "Date",
        "Moniteur",
        "Site",
        "Profondeur (m)",
        "Temps (min)",
        "Commentaire général",
      ];
      const instructorRows =
        training.Sessions?.map((session) => [
          `Séance ${session.Number}`,
          session.Date,
          session.Instructor,
          session.DiveSite,
          session.DiveDepth,
          session.DiveTime,
          session.GlobalComment,
        ]) || [];

      doc.autoTable({
        startY: cursorY,
        head: [instructorColumn],
        body: instructorRows,
        styles: { cellPadding: 1, fontSize: 9 },
        didDrawPage: (data) => {
          cursorY = data.cursor.y;
        },
      });

      cursorY = doc.autoTableEndPosY() + 10; // Add spacing after the table

      Object.keys(moduleSummaries).forEach((moduleName, index) => {
        const module = moduleSummaries[moduleName];

        // Diviser le texte si le titre est trop long pour tenir sur une ligne
        const moduleTitleLines = doc.splitTextToSize(
          `Module: ${moduleName}`,
          pageWidth - 2 * margin
        );

        // Check if there is enough space for the title + some rows on the page, if not, add a new page
        if (cursorY + moduleTitleLines.length * 10 + 10 > pageHeight - 40) {
          // 40 to account for footer space on each page
          doc.addPage();
          cursorY = 20; // Reset cursorY after adding a new page
        }

        doc.setFontSize(14);
        doc.text(moduleTitleLines, margin, cursorY);
        cursorY += moduleTitleLines.length * 5;

        const tableColumn = ["Description", "Critères", "Commentaires"];
        const tableRows: any[] = [];

        module.forEach((criterion) => {
          const comments = criterion.comments.length
            ? criterion.comments
                .map((c) => `Séance ${c.sessionNumber}: ${c.comment}`)
                .join("\n")
            : "";

          const rowData = [criterion.description, criterion.status, comments];
          tableRows.push(rowData);
        });

        doc.autoTable({
          startY: cursorY,
          head: [tableColumn],
          body: tableRows,
          styles: { cellPadding: 1, fontSize: 8 },
          columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 50 },
            2: { cellWidth: 80 },
          },
          didDrawCell: (data) => {
            // Ensure we only apply the color to body cells, not header cells
            if (data.row.section === "body" && data.column.index === 0) {
              const criterion = module[data.row.index];
              if (criterion) {
                // Add this check to avoid accessing undefined properties
                const color = getColorForCriterion(criterion);
                doc.setDrawColor(color);
                doc.setFillColor(color);
                doc.rect(
                  data.cell.x - 5,
                  data.cell.y,
                  1,
                  data.cell.height,
                  "F"
                );
              }
            }
          },
          didDrawPage: (data) => {
            cursorY = data.cursor.y; // Update the cursorY after drawing the table
          },
        });

        cursorY = doc.autoTableEndPosY() + 10; // Add some spacing after the table
      });

      // Ajouter le pied de page (signature) uniquement à la dernière page
      const date = new Date().toLocaleDateString();

      doc.setFontSize(10);
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.line(margin, pageHeight - 25, pageWidth - margin, pageHeight - 25); // Ligne au-dessus du pied de page
      doc.text(`Date: ${date}`, margin, pageHeight - 20);
      doc.text(`Signature : ${user?.Name}`, margin, pageHeight - 10);

      // Generate the PDF as a Blob
      const pdfBlob = doc.output("blob");

      uploadSheetFile(pdfBlob);

      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "fixed",
          top: 64,
          left: 0,
          width: "100vw",
          height: "calc(100vh - 64px)",
          zIndex: "199",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "calc(90% - 50px)",
            overflow: "auto",
            borderRadius: "10px",
            padding: "1rem",
            zIndex: "200",
            backgroundColor: "white",
            textAlign: "start",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {customer?.FirstName +
              " " +
              customer?.LastName +
              " - " +
              training.Qualification}
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Séances</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Moniteur</TableCell>
                  <TableCell>Site</TableCell>
                  <TableCell>Profondeur (m)</TableCell>
                  <TableCell>Temps (min)</TableCell>
                  <TableCell>Commentaire général</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {training.Sessions?.map((session, idx) => (
                  <TableRow key={idx}>
                    <TableCell>Séance {session.Number}</TableCell>
                    <TableCell>{session.Date}</TableCell>
                    <TableCell>{session.Instructor}</TableCell>
                    <TableCell>{session.DiveSite}</TableCell>
                    <TableCell>{session.DiveDepth}</TableCell>
                    <TableCell>{session.DiveTime}</TableCell>
                    <TableCell>{session.GlobalComment}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>

          {Object.keys(moduleSummaries).map((moduleName, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Module: {moduleName}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={"25%"}>Description</TableCell>
                    <TableCell width={"25%"}>Critères</TableCell>
                    <TableCell width={"50%"}>Commentaires</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {moduleSummaries[moduleName].map((criterion, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "& td:first-of-type": {
                          borderLeft: `5px solid ${getColorForCriterion(
                            criterion
                          )}`,
                        },
                      }}
                    >
                      <TableCell>{criterion.description}</TableCell>
                      <TableCell>{criterion.status}</TableCell>
                      <TableCell>
                        {criterion.comments.length > 0 ? (
                          <ul>
                            {criterion.comments.map((comment, cIdx) => (
                              <li key={cIdx}>
                                Session {comment.sessionNumber}:{" "}
                                {comment.comment}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            position: "fixed",
            left: "50%",
            bottom: -20,
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "50px",
            padding: "1rem",
            overflow: "auto",
            borderRadius: "10px",
            zIndex: "200",
            backgroundColor: "white",
            textAlign: "start",
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            {isAdmin() && location.pathname === "/office" ? (
              <Grid item>
                <SAvatar>
                  <Tooltip title="Générer PDF">
                    <SGenerate
                      sx={{ color: "white" }}
                      onClick={handleGeneratePdf}
                    />
                  </Tooltip>
                </SAvatar>
              </Grid>
            ) : null}
            <Grid item>
              <SAvatar>
                <SClose sx={{ color: "white" }} onClick={onClose} />
              </SAvatar>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
