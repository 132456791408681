import { Button, Grid, TextField } from "@mui/material";
import { SLogin, SLogo, STypography } from "../../styles/StyledMUI";
import Zekay from "../../styles/images/zekay_logo.svg";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { LoginForm } from "../../api/auth/Auth.typed";
import { useAlert } from "../../hooks/useAlert";

export default function ResetPasswordPage() {
  const [password1, setPassword1] = React.useState<string>("");
  const [password2, setPassword2] = React.useState<string>("");
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const { verifyToken, resetPassword } = React.useContext(AuthContext);
  const location = useLocation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const handleReset = async () => {
    const token = new URLSearchParams(location.search).get("token");
    const email = new URLSearchParams(location.search).get("email");

    if (password1.length > 8) {
      if (token !== null && email !== null) {
        if (password1 === password2) {
          const h: LoginForm = {
            Email: email,
            Token: token,
            Password: password2,
          };

          resetPassword(h)
            .then(() => navigate("/"))
            .catch((err) => {
              if (err instanceof Error) {
                showAlert(err.message, "error");
              }
            });
        } else {
          showAlert("Les mots de passe ne sont pas identiques", "error");
        }
      }
    } else {
      showAlert(
        "La longueur du mot de passe est inférieure à 8 caractères",
        "error"
      );
    }
  };

  React.useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");

    if (token !== null) {
      const h: LoginForm = {
        Token: token,
      };

      verifyToken(h)
        .then((r) => (r ? setIsValid(true) : setIsValid(false)))
        .catch((err) => {
          if (err instanceof Error) {
            setIsValid(false);
            showAlert(err.message, "error");
          }
        });
    } else {
      setIsValid(false);
    }
  }, []);

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <SLogo sx={{ top: "60%" }}>
          <img src={Zekay} width="400px" height="auto" alt="Logo" />
        </SLogo>
      </Grid>
      {isValid ? (
        <Grid item xs={12}>
          <SLogin>
            <TextField
              label="Mot de passe"
              variant="standard"
              sx={{ margin: "10px", width: "90%" }}
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <TextField
              label="Confirmez le mot de passe"
              variant="standard"
              type="password"
              value={password2}
              sx={{ margin: "10px", width: "90%" }}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={handleReset}
              sx={{ margin: "10px", width: "50%", height: "40px" }}
            >
              Soumettre
            </Button>
          </SLogin>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <SLogin>
            <STypography textAlign="center">Désolé :(</STypography>
            <STypography textAlign="center">Cette page a expiré</STypography>
          </SLogin>
        </Grid>
      )}
    </Grid>
  );
}
