import React, { ChangeEvent } from "react";
import {
  STable,
  STypography,
  STypographyItem,
} from "../../../../styles/StyledMUI";
import { Box } from "@mui/system";
import {
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Training, TrainingTemplate } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import TrainingEmptyList from "../display/trainingEmptyList";
import TrainingDoneItem from "./trainingDoneItem";

export default function TrainingDoneList() {
  const [qualifications, setQualifications] = React.useState<TrainingTemplate[]>();
  const [creditId, setCreditId] = React.useState<string>("");
  const [trainings, setTrainings] = React.useState<Training[]>();
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getTrainingsTemplates, getTrainingsByStatusCreditId } = useZekay();

  // Fetch qualifications on component mount
  React.useEffect(() => {
    getTrainingsTemplates().then((templates) => setQualifications(templates));
  }, [getTrainingsTemplates]);

  // Fetch trainings based on selected qualification
  React.useEffect(() => {
    if (creditId) {
      setIsLoading(true);
      getTrainingsByStatusCreditId(creditId, 3)
        .then((trainings) => setTrainings(trainings || []))
        .finally(() => setIsLoading(false));
    }
  }, [creditId, getTrainingsByStatusCreditId]);

  const handleChangeQualification = (e: SelectChangeEvent<string>) => {
    setCreditId(e.target.value);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  // Filter trainings based on search input
  const filteredTrainings = trainings?.filter((t) =>
    t.LastName?.toLowerCase().startsWith(searchInput.toLowerCase())
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl sx={{ minWidth: "400px", marginBottom: "10px" }}>
          <InputLabel>
            <STypography>Formations</STypography>
          </InputLabel>
          <Select
            value={creditId}
            label="Formations"
            onChange={handleChangeQualification}
            sx={{ fontSize: "15px" }}
          >
            {qualifications?.map((q) => (
              <MenuItem key={q.ID} value={q.CreditID}>
                {q.Agency + " - " + q.Qualification}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isLoading && (
          <Box sx={{ display: "flex", marginLeft: "10px", marginBottom: "10px" }}>
            <CircularProgress />
          </Box>
        )}
        {creditId && !isLoading && (
          <Grid container item justifyContent="flex-end">
            <TextField
              label="Rechercher"
              variant="outlined"
              value={searchInput}
              onChange={handleSearch}
              sx={{ width: "350px", marginBottom: "10px" }}
            />
          </Grid>
        )}
      </Box>
      <Divider />
      {filteredTrainings && filteredTrainings.length > 0 ? (
        <STable>
          <TableBody>
            <TableRow>
              {["Prénom", "Nom", "Organisation", "Qualification", "Commentaires", "Sessions théoriques", "Documents", "Résultats QCM", "Profil", "Zuurit"].map(
                (header, index) => (
                  <TableCell key={index} sx={{ width: "5%" }} align="left">
                    <STypographyItem>{header}</STypographyItem>
                  </TableCell>
                )
              )}
            </TableRow>
            {filteredTrainings.map((t) => (
              <TrainingDoneItem
                training={t}
                key={t.CustomerID}
                setTrainings={setTrainings}
              />
            ))}
          </TableBody>
        </STable>
      ) : (
        <STable>
          <TableBody>
            <TrainingEmptyList />
          </TableBody>
        </STable>
      )}
    </Box>
  );
}
