import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  ExcursionsStats,
  StatDate,
  StatsHandler,
} from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import { useAlert } from "../../../../hooks/useAlert";
import { STypography, STypographyTitle } from "../../../../styles/StyledMUI";
import { Box } from "@mui/system";
import { formatMonth } from "../../../../utils/dateTime";

export default function BoatCustomerCount() {
  const [dates, setDates] = React.useState<StatDate[]>();
  const [year, setYear] = React.useState<string>("");
  const [excursionsStats, setExcursionsStats] =
    React.useState<ExcursionsStats[]>();
  const { showAlert } = useAlert();
  const { getStatDates, getExcursionsStatsByYear } = useZekay();

  React.useEffect(() => {
    getStatDates()
      .then((dates) => setDates(dates))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  }, []);

  const handleChangeByYear = (e: SelectChangeEvent<string>) => {
    setYear(e.target.value);

    const date: StatsHandler = {
      Year: Number(e.target.value),
      Month: 0,
    };

    getExcursionsStatsByYear(date)
      .then((stats) => setExcursionsStats(stats))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  return (
    <React.Fragment>
      <STypographyTitle padding={2}>
        Taux d'occupation du bateau
      </STypographyTitle>
      <FormControl sx={{ width: "110px", left: "20px" }}>
        <InputLabel>
          <STypography>Année</STypography>
        </InputLabel>
        <Select
          value={year}
          label="Année"
          onChange={(e: SelectChangeEvent<string>) => handleChangeByYear(e)}
        >
          {dates?.map((date, index) => {
            return (
              <MenuItem key={index} value={date.Year}>
                {date.Year}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box padding={2}>
        <ResponsiveContainer width="100%" height="auto" aspect={4}>
          <LineChart
            data={excursionsStats}
            margin={{
              top: 5,
              right: 50,
              left: 10,
              bottom: 5,
            }}
          >
            <Line type="monotone" dataKey="UserCountAvg" stroke="blue" name="Moniteurs" />
            <Line type="monotone" dataKey="CustomerCountAvg" stroke="green" name="Clients" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="Month" tickFormatter={formatMonth} />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </React.Fragment>
  );
}
