import React from "react";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Training } from "../../api/zekay/Zekay.typed";
import useAuth from "../../contexts/controllers/useAuth";
import { SArrow, SReset, STypographyItem } from "../../styles/StyledMUI";
import { Collapse, Table, TableBody, TableCell, TableRow } from "@mui/material";

// Activer les plugins utc et timezone
dayjs.extend(utc);
dayjs.extend(timezone);

interface DatesComponentProps {
  training: Training;
  theory: Dayjs | null;
  rifap: Dayjs | null;
  setTheory: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setRifap: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  updateTraining: (training: Training) => Promise<void>;
  getTrainingsByCreditId: (creditId: string) => Promise<Training[] | undefined>;
  setTrainings: React.Dispatch<React.SetStateAction<Training[] | undefined>>;
  showAlert: (message: string, severity: "error" | "success") => void;
  isOpenDates: boolean;
}

export default function DatesComponent({
  training,
  theory,
  rifap,
  setTheory,
  setRifap,
  updateTraining,
  getTrainingsByCreditId,
  setTrainings,
  showAlert,
  isOpenDates,
}: DatesComponentProps) {
  const { user } = useAuth();

  const handleDateChange = (event: Dayjs, type: "theory" | "rifap") => {
    const utcDate = event.utc(); // Convertir la date en UTC
    if (type === "theory") {
      setTheory(utcDate);
    } else {
      setRifap(utcDate);
    }

    const tr: Training = {
      ...training,
      Theory:
        type === "theory" ? utcDate.toISOString() : theory?.toISOString() || "",
      RifapDate:
        type === "rifap" ? utcDate.toISOString() : rifap?.toISOString() || "",
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(tr)
      .then(() => getTrainingsByCreditId(String(training?.CreditId)))
      .then((ts) => setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  const handleResetDate = (type: "theory" | "rifap") => {
    if (type === "theory") {
      setTheory(null);
    } else {
      setRifap(null);
    }

    const tr: Training = {
      ...training,
      Theory: type === "theory" ? "" : theory?.toISOString() || "",
      RifapDate: type === "rifap" ? "" : rifap?.toISOString() || "",
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(tr)
      .then(() => getTrainingsByCreditId(String(training?.CreditId)))
      .then((ts) => setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  return (
    <TableCell
      style={{
        paddingBottom: 0,
        paddingTop: 0,
        margin: "auto",
        border: isOpenDates ? "" : "none",
      }}
      colSpan={7}
    >
      <Collapse in={isOpenDates} timeout="auto" unmountOnExit>
        <Table sx={{ width: "100%", border: "none" }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "5%", border: "none" }} align="left">
                <SArrow />
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                }}
              >
                {training.NeedsTheory && (
                  <>
                    <STypographyItem>Cours théorique</STypographyItem>
                    <Box display="flex" alignItems="center" columnGap={1}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                      >
                        <DatePicker
                          disableFuture
                          format="DD/MM/YYYY"
                          onChange={(e: any) => handleDateChange(e, "theory")}
                          value={theory}
                          sx={{
                            zIndex: "0",
                            marginTop: 1,
                            "& .MuiOutlinedInput-root": { fontSize: "12px" },
                            "& .MuiInputLabel-root": { fontSize: "13px" },
                          }}
                        />
                      </LocalizationProvider>
                      {theory && (
                        <SReset onClick={() => handleResetDate("theory")} />
                      )}
                    </Box>
                  </>
                )}
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                }}
              >
                {training.NeedsRifap && (
                  <>
                    <STypographyItem>RIFAP</STypographyItem>
                    <Box display="flex" alignItems="center" columnGap={1}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                      >
                        <DatePicker
                          disableFuture
                          format="DD/MM/YYYY"
                          onChange={(e: any) => handleDateChange(e, "rifap")}
                          value={rifap}
                          sx={{
                            zIndex: "0",
                            marginTop: 1,
                            "& .MuiOutlinedInput-root": { fontSize: "12px" },
                            "& .MuiInputLabel-root": { fontSize: "13px" },
                          }}
                        />
                      </LocalizationProvider>
                      {rifap && (
                        <SReset onClick={() => handleResetDate("rifap")} />
                      )}
                    </Box>
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
}
