import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";

type AddressComponentProps = {
  street: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
  setStreet: Dispatch<SetStateAction<string | undefined>>;
  setPostalCode: Dispatch<SetStateAction<string | undefined>>;
  setCity: Dispatch<SetStateAction<string | undefined>>;
};

export default function AddressComponent(props: AddressComponentProps) {
  const handlePostalCodeChange = (event: any) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    props.setPostalCode(inputValue);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Numéro et rue"
            variant="standard"
            sx={{ margin: "10px", width: "90%" }}
            value={props.street == undefined ? "" : props.street}
            onChange={(e) => props.setStreet(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Code Postal"
            variant="standard"
            sx={{ margin: "10px", width: "90%" }}
            value={props.postalCode == undefined ? "" : props.postalCode}
            onChange={handlePostalCodeChange}
            inputProps={{ pattern: "[0-9]*" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Ville"
            variant="standard"
            sx={{ margin: "10px", width: "90%" }}
            value={props.city == undefined ? "" : props.city}
            onChange={(e) => props.setCity(e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
