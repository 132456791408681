import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { SLogo2 } from "../../styles/StyledMUI";
import Escapade from "../../styles/images/logo_escapade-long-plein_RVB_Blanc.svg";
import React from "react";
import AddressComponent from "./display/AddressComponent";
import FileComponent from "./display/FileComponent";
import PhotoComponent from "./display/PhotoComponent";
import SaveIcon from "@mui/icons-material/Save";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PhoneComponent from "./display/PhoneComponent";
import BirthdateComponent from "./display/BirthdayComponent";
import { PortalResponse } from "../../api/auth/Auth.typed";
import PortalLoginModal from "./display/PortalLoginModal";
import dayjs, { Dayjs } from "dayjs";
import useAuth from "../../contexts/controllers/useAuth";
import Cookies from "js-cookie";
import { ZekayCustomer } from "../../api/zekay/Zekay.typed";
import { useAlert } from "../../hooks/useAlert";
import TrainingDocs from "./display/TrainingDocs";
import GdprComponent from "./display/GdprComponent";
import TrainingTest from "./display/TrainingTest";
import FileWithPDFComponent from "./display/FileWithPDFComponent";

export default function PortalPage() {
  const [customer, setCustomer] = React.useState<PortalResponse>();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [street, setStreet] = React.useState<string | undefined>("");
  const [postalCode, setPostalCode] = React.useState<string | undefined>("");
  const [city, setCity] = React.useState<string | undefined>("");
  const [photo, setPhoto] = React.useState<
    string | Blob | File | ProgressEvent<FileReader>
  >();
  const [medical, setMedical] = React.useState<
    string | Blob | File | ProgressEvent<FileReader>
  >();
  const [disclaimer, setDisclaimer] = React.useState<
    string | Blob | File | ProgressEvent<FileReader>
  >();
  const [agreement, setAgreement] = React.useState<
    string | Blob | File | ProgressEvent<FileReader>
  >();
  const [phonenumber, setPhonenumber] = React.useState<string>("");
  const [birthdate, setBirthdate] = React.useState<Dayjs | undefined | null>(
    null
  );
  const [gdpr, setGdpr] = React.useState<boolean>(false);
  const { updatePortalCustomerInfo } = useAuth();
  const { showAlert } = useAlert();

  React.useEffect(() => {
    const b =
      customer?.Birthdate === undefined || customer?.Birthdate === "01-01-1900"
        ? null
        : dayjs.utc(customer?.Birthdate, "DD-MM-YYYY");

    if (customer) {
      setStreet(customer.Street);
      setPostalCode(customer.PostalCode);
      setCity(customer.City);
      setBirthdate(b);
      setPhonenumber(customer.Phonenumber);
      setPhoto(customer.Photo);
      setMedical(customer.MedicalCertificate);
      setAgreement(customer.MedicalAgreement);
      setDisclaimer(customer.MedicalDisclaimer);
      setGdpr(customer.GdprConsent);
    }
  }, [customer]);

  const handleSave = async () => {
    setIsLoading(true);

    const authToken = Cookies.get("authToken");

    try {
      if (authToken !== undefined) {
        const c: ZekayCustomer = {
          Id: customer?.Id,
          Street: street,
          PostalCode: postalCode,
          City: city,
          Birthdate:
            birthdate == null
              ? dayjs("01-01-1900").format("YYYY-MM-DD") + "T00:00:00Z"
              : dayjs(birthdate).format("YYYY-MM-DD") + "T00:00:00Z",
          Phonenumber: phonenumber,
          Photo: photo,
          MedicalCertificate: medical,
          MedicalAgreement: agreement,
          MedicalDisclaimer: disclaimer,
          GdprConsent: gdpr,
        };

        await updatePortalCustomerInfo(c, authToken)
          .then((c) => {
            showAlert("Informations envoyées !", "success");
          })
          .catch((err) => {
            setIsLoggedIn(false);
            showAlert("Session expirée", "info");
          });
      } else {
        setIsLoggedIn(false);
        showAlert("Session expirée", "info");
      }
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "error");
      }
    }
    setIsLoading(false);
  };

  const handleSignOff = () => {
    Cookies.remove("authToken");
    setIsLoggedIn(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "black",
        fontFamily: '"Roboto", sans-serif',
        color: "white",
      }}
    >
      <SLogo2>
        <img src={Escapade} width="300px" height="auto" alt="Logo" />
      </SLogo2>
      {isLoggedIn ? (
        <Box sx={{ position: "absolute", top: "170px" }}>
          <Typography
            variant="h4"
            component="h1"
            color="white"
            fontSize={"17px"}
            gutterBottom
          >
            {"Bonjour " + customer?.FirstName + " !"}
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            color="white"
            fontSize={"14px"}
            gutterBottom
          >
            {"Formation " + customer?.Agency + " - " + customer?.Qualification}
          </Typography>
        </Box>
      ) : null}
      {isLoggedIn ? (
        <Avatar
          sx={{
            bgcolor: "primary.main",
            width: 56,
            height: 56,
            cursor: "pointer",
            position: "absolute",
            top: 16,
            right: 16,
          }}
          onClick={handleSignOff}
        >
          <ExitToAppIcon sx={{ color: "white" }} />
        </Avatar>
      ) : null}
      {!isLoggedIn ? (
        <PortalLoginModal
          setIsLoggedIn={setIsLoggedIn}
          setCustomer={setCustomer}
        />
      ) : (
        <>
          <Box
            sx={{
              position: "absolute",
              top: "220px",
              left: 0,
              right: 0,
              bottom: "100px",
              overflowY: "auto",
              padding: 3,
            }}
          >
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Documents théoriques à consulter
              </Typography>
              <TrainingDocs
                course1={customer?.Course1Url}
                course2={customer?.Course2Url}
                theoryUrl={customer?.TheoryUrl}
              />
            </Box>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Test en ligne
              </Typography>
              <TrainingTest testUrl={customer?.TestUrl} />
            </Box>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Adresse
              </Typography>
              <AddressComponent
                street={street}
                postalCode={postalCode}
                city={city}
                setStreet={setStreet}
                setPostalCode={setPostalCode}
                setCity={setCity}
              ></AddressComponent>
            </Box>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Téléphone
              </Typography>
              <PhoneComponent
                phonenumber={phonenumber}
                setPhonenumber={setPhonenumber}
              />
            </Box>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Date de naissance
              </Typography>
              <BirthdateComponent
                birthdate={birthdate}
                setBirthdate={setBirthdate}
              />
            </Box>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Photo
              </Typography>
              <PhotoComponent photo={photo} setPhoto={setPhoto} />
            </Box>
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Certificat médical
                <Typography fontSize={"12px"}>(PDF uniquement)</Typography>
              </Typography>
              <FileComponent
                file={medical}
                setFile={setMedical}
                title={"Certificat médical"}
              />
            </Box>
            {customer?.HasSdiTraining ? (
              <>
                <Box margin={1}>
                  <Typography
                    variant="h5"
                    component="h3"
                    color="white"
                    fontSize={"16px"}
                    gutterBottom
                  >
                    Décharge de responsabilité SDI
                  </Typography>
                  <FileWithPDFComponent
                    file={disclaimer}
                    setFile={setDisclaimer}
                    title="Décharge de responsabilité"
                    pdf={customer?.MedicalDisclaimerTemplate}
                  />
                </Box>
                <Box margin={1}>
                  <Typography
                    variant="h5"
                    component="h3"
                    color="white"
                    fontSize={"16px"}
                    gutterBottom
                  >
                    Décharge médicale SDI
                  </Typography>
                  <FileWithPDFComponent
                    file={agreement}
                    setFile={setAgreement}
                    title="Décharge médicale"
                    pdf={customer?.MedicalAgreementTemplate}
                  />
                </Box>
              </>
            ) : null}
            <Box margin={1}>
              <Typography
                variant="h5"
                component="h3"
                color="white"
                fontSize={"16px"}
                gutterBottom
              >
                Préferences de communication
              </Typography>
              <GdprComponent gdpr={gdpr} setGdpr={setGdpr} />
            </Box>
          </Box>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "100px",
              backgroundColor: "black",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "primary.main",
                width: 56,
                height: 56,
                cursor: "pointer",
              }}
              onClick={handleSave}
            >
              {!isLoading ? (
                <SaveIcon sx={{ color: "white" }} />
              ) : (
                <CircularProgress size={24} sx={{ color: "white" }} />
              )}
            </Avatar>
          </Box>
        </>
      )}
    </Box>
  );
}
