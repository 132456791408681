import { Dispatch, SetStateAction } from "react";
import { SArrow, SSave } from "../../styles/StyledMUI";
import {
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

interface CommentsComponentProps {
  comments: string | undefined;
  setComments: Dispatch<SetStateAction<string | undefined>>;
  handleUpdate: () => void;
  isOpenComments: boolean;
  setIsOpenComments: Dispatch<SetStateAction<boolean>>;
}

export default function CommentsComponent(props: CommentsComponentProps) {
  return (
    <TableCell
      style={{
        paddingBottom: 0,
        paddingTop: 0,
        margin: "auto",
        border: props.isOpenComments ? "" : "none",
      }}
      colSpan={7}
    >
      <Collapse in={props.isOpenComments} timeout="auto" unmountOnExit>
        <Table sx={{ width: "100%", border: "none" }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "5%", border: "none" }} align="left">
                <SArrow />
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                }}
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{ margin: "5px", zIndex: 0 }}
                >
                  <InputLabel>Commentaires</InputLabel>
                  <OutlinedInput
                    label="Commentaires"
                    value={props.comments !== undefined ? props.comments : ""}
                    onChange={(e) => props.setComments(e.target.value)}
                    sx={{ fontSize: "15px" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={props.handleUpdate}>
                          <SSave />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
}
