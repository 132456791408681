import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

type BirthdateComponentProps = {
  birthdate: dayjs.Dayjs | null | undefined;
  setBirthdate: Dispatch<SetStateAction<dayjs.Dayjs | null | undefined>>;
};

export default function BirthdateComponent(props: BirthdateComponentProps) {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker
          disableFuture
          format="DD/MM/YYYY"
          value={props.birthdate}
          timezone="UTC"
          onChange={(e: any) => props.setBirthdate(e)}
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>
    </Box>
  );
}
