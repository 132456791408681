import React from "react";
import CustomersList from "./CustomersList";
import { ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import SearchFab from "../../nav/SearchFab";
import { Box } from "@mui/system";
import useZekay from "../../../contexts/controllers/useZekay";
import { STypographyItem } from "../../../styles/StyledMUI";

export default function CustomersComponent() {
  const [searchList, setSearchList] = React.useState<
    ZekayCustomer[] | undefined
  >(undefined);
  const [updatedAt, setUpdatedAt] = React.useState<string | null>(null);

  const { getLogsByTable } = useZekay();

  React.useEffect(() => {
    const fetchUpdateLog = async () => {
      try {
        const log = await getLogsByTable("customers");
        if (log) {
          setUpdatedAt(log.UpdatedAt);
        }
      } catch (error) {
        console.error("Failed to fetch update log:", error);
      }
    };

    fetchUpdateLog();
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          height: "calc(100vh - 64px)",
          width: "100%",
          top: 64,
          left: 0,
          overflow: "auto",
        }}
      >
        <SearchFab searchList={searchList} setSearchList={setSearchList} />
        <CustomersList customers={searchList} />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "20%",
          backgroundColor: "black",
          padding: 1,
          borderRadius: "0 10px 0px 10px",
        }}
      >
        <STypographyItem sx={{ color: "white" }}>
          {updatedAt
            ? `Dernière mise à jour depuis Zuurit: ${new Date(
                updatedAt
              ).toLocaleString()}`
            : "Chargement..."}
        </STypographyItem>
      </Box>
    </>
  );
}
