import { Button, TextField } from "@mui/material";
import { SClose, SFormLarge, STypography } from "../../../styles/StyledMUI";
import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/system";
import { AuthContext } from "../../../contexts/AuthContext";
import { LoginForm } from "../../../api/auth/Auth.typed";

type ResetComponentProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ResetComponent(props: ResetComponentProps) {
  const [email, setEmail] = React.useState<string>("");
  const { resetRequest } = React.useContext(AuthContext);

  const handleReset = async () => {
    const f: LoginForm = {
      Email: email,
    };

    resetRequest(f);
    props.setIsOpen(false);
  };

  return (
    <SFormLarge>
      <Box display="flex">
        <STypography>
          Entrez votre adresse e-mail pour renouveler votre mot de passe :
        </STypography>
        <SClose sx={{ margin: "4px" }} onClick={() => props.setIsOpen(false)} />
      </Box>
      <Box>
        <TextField
          label="Email"
          variant="standard"
          sx={{ margin: "10px", width: "90%" }}
          onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
          value={email}
        />
      </Box>
      {email !== "" ? (
        <Button
          variant="contained"
          sx={{ margin: "10px", width: "50%", height: "40px" }}
          onClick={handleReset}
        >
          Soumettre
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{ margin: "10px", width: "50%", height: "40px" }}
          disabled
        >
          Soumettre
        </Button>
      )}
    </SFormLarge>
  );
}
