import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

body {
  background-color: black ;
  font-family: "Roboto", sans-serif;
  overflow: auto;
}

`;

export default GlobalStyles;
