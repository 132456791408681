import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TrainingTemplateEditor from "./display/TrainingTemplatesEditor";
import CourseTemplateEditor from "./display/CourseTemplateEditor";

export default function TemplatesPage() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="Formations" value="1" />
              <Tab label="Suivis" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TrainingTemplateEditor />
          </TabPanel>
          <TabPanel value="2">
            <CourseTemplateEditor />
          </TabPanel>
        </TabContext>
      </Box>
      <Box
        style={{
          textAlign: "right",
          position: "fixed",
          right: 0,
          bottom: 0,
          width: "20%",
          backgroundColor: "white",
        }}
      ></Box>
    </>
  );
}
