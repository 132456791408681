import React, { ChangeEvent } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import { Box } from "@mui/system";
import {
  SAddDocument,
  SAvatar,
  SDelete,
  SMissingFile,
  STypographyItem,
  SView,
} from "../../../styles/StyledMUI";

interface DocumentTypeComponentProps {
  uploadFile: (event: any, type: string) => Promise<void>;
  deleteFile: (fileType: string) => Promise<void>;
  handleView: (type: string) => void;
  title: string;
  document: string | undefined;
  documentType: string;
}

export default function DocumentTypeComponent(
  props: DocumentTypeComponentProps
) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    props.uploadFile(e, props.documentType).then(() => setIsLoading(false));
  };

  return (
    <Table sx={{ border: "none" }}>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              width: "100%",
              border: "none",
              textAlign: "center",
              paddingTop: 1,
            }}
          >
            <STypographyItem>{props.title}</STypographyItem>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              width: "100%",
              height: "auto",
              border: "none",
              margin: "auto",
            }}
          >
            {!isLoading ? (
              <>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleUpload}
                      accept="application/pdf"
                    />
                    <Tooltip title="Ajouter document">
                      <SAvatar style={{ marginRight: "15px" }}>
                        <SAddDocument />
                      </SAvatar>
                    </Tooltip>
                  </label>
                  {props.document !== undefined ? (
                    <Box display="flex" alignItems="center" columnGap={2}>
                      <Tooltip title="Voir document">
                        <SAvatar>
                          <SView
                            onClick={() => props.handleView(props.documentType)}
                          />
                        </SAvatar>
                      </Tooltip>
                      <Tooltip title="Supprimer document">
                        <SDelete
                          onClick={() => props.deleteFile(props.documentType)}
                        />
                      </Tooltip>
                    </Box>
                  ) : (
                    <>
                      <SMissingFile />
                    </>
                  )}
                </Box>
              </>
            ) : (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
