import { Box } from "@mui/system";
import {
  SAvatar,
  SBackground,
  STable,
  STypographyItem,
  STypographyTitle,
  SUserAdd,
} from "../../../styles/StyledMUI";
import {
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import useAuth from "../../../contexts/controllers/useAuth";
import React from "react";
import { User } from "../../../api/auth/Auth.typed";
import { useAlert } from "../../../hooks/useAlert";
import UserItem from "./display/userItem";
import UserInviteComponent from "./display/userInviteComponent";

export default function UserList() {
  const { getUsers } = useAuth();
  const [users, setUsers] = React.useState<User[]>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { showAlert } = useAlert();

  React.useEffect(() => {
    getUsers()
      .then((us) => setUsers(us))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <STypographyTitle padding={2}>Utilisateurs</STypographyTitle>
        <SAvatar sx={{ position: "absolute", right: "40px" }}>
          <Tooltip title="Inviter utilisateur">
            <SUserAdd onClick={() => setIsOpen(true)} />
          </Tooltip>
        </SAvatar>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <STable>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "5%" }} align="left">
                <STypographyItem>Prénom</STypographyItem>
              </TableCell>
              <TableCell sx={{ width: "5%" }} align="left">
                <STypographyItem>Email</STypographyItem>
              </TableCell>
              <TableCell sx={{ width: "5%" }} align="left">
                <STypographyItem>Rôle</STypographyItem>
              </TableCell>
            </TableRow>
            {users?.map((u): JSX.Element | undefined => {
              return <UserItem key={u.ID} user={u} />;
            })}
          </TableBody>
        </STable>
      </Box>
      {isOpen ? (
        <SBackground>
          <UserInviteComponent setIsOpen={setIsOpen} />
        </SBackground>
      ) : null}
    </Box>
  );
}
