import React from "react";
import { SGroups, SNotes, SSettings, SUsers } from "../../styles/StyledMUI";
import UserList from "./users/userList";
import LogsPage from "../settings/logs/LogsPage";
import { useNavigate } from "react-router-dom";
import useAuth from "../../contexts/controllers/useAuth";
import { Box } from "@mui/system";
import SideBar from "../nav/SideBar";
import GeneralSettingsPage from "./general/GeneralSettingsPage";
import GroupsComponent from "./users/groups/GroupsComponent";

const sidebarConfig = [
  {
    label: "Général",
    icon: <SSettings />,
    component: <GeneralSettingsPage />,
  },
  {
    label: "Logs",
    icon: <SNotes />,
    component: <LogsPage />,
  },
  {
    label: "Utilisateurs",
    icon: <SUsers />,
    component: <UserList />,
  },
  {
    label: "Groupes",
    icon: <SGroups />,
    component: <GroupsComponent />,
  },
];

export default function AdminPage() {
  const [page, setPage] = React.useState<JSX.Element>(
    sidebarConfig[0].component
  );
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    if (!isAdmin()) {
      navigate("/menu");
    }
  }, []);

  return (
    <Box sx={{ position: "fixed", height: "calc(100vh - 64px)" }}>
      <SideBar config={sidebarConfig} setPage={setPage} />
      <Box
        sx={{
          position: "fixed",
          top: "64px",
          left: "12%",
          right: 0,
          bottom: 0,
          overflowY: "auto",
          backgroundColor: "white",
          width: "88%",
        }}
      >
        {page}
      </Box>
    </Box>
  );
}
