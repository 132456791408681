import React from "react";
import {
  STable,
  STypography,
  STypographyItem,
} from "../../../../styles/StyledMUI";
import { Box } from "@mui/system";
import {
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Training, TrainingTemplate } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import TrainingInProgressItem from "./trainingInProgressItem";
import TrainingEmptyList from "../display/trainingEmptyList";

export default function TrainingInProgressList() {
  const [qualifications, setQualifications] = React.useState<TrainingTemplate[]>();
  const [creditId, setCreditId] = React.useState<string>("");
  const [trainings, setTrainings] = React.useState<Training[]>();
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getTrainingsTemplates, getTrainingsByStatusCreditId } = useZekay();

  React.useEffect(() => {
    getTrainingsTemplates().then((t) => setQualifications(t));
  }, [getTrainingsTemplates]);

  React.useEffect(() => {
    if (creditId !== "") {
      setIsLoading(true);
      getTrainingsByStatusCreditId(creditId, 1)
        .then((ts) => setTrainings(ts || []))
        .finally(() => setIsLoading(false));
    }
  }, [creditId, getTrainingsByStatusCreditId]);

  const handleChangeQualification = (e: SelectChangeEvent<string>) => {
    setCreditId(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const filteredTrainings = trainings?.filter((t) =>
    t.LastName?.toLocaleLowerCase().startsWith(searchInput.toLocaleLowerCase())
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl sx={{ minWidth: "400px", marginBottom: "10px" }}>
          <InputLabel>
            <STypography>Formations</STypography>
          </InputLabel>
          <Select
            value={creditId}
            label="Formations"
            onChange={handleChangeQualification}
            sx={{ fontSize: "15px" }}
          >
            {qualifications?.map((q) => (
              <MenuItem key={q.ID} value={q.CreditID}>
                {q.Agency + " - " + q.Qualification}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {trainings && trainings.length > 0 && !isLoading && (
          <STypographyItem
            sx={{ width: "150px", marginLeft: "10px", marginBottom: "10px" }}
          >
            {"En cours : " +
              trainings.filter((t) => t.Status === "En cours").length}
          </STypographyItem>
        )}
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              marginLeft: "10px",
              marginBottom: "10px",
              width: "150px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {creditId && !isLoading && (
          <Grid container item justifyContent="flex-end">
            <TextField
              label="Rechercher"
              variant="outlined"
              value={searchInput}
              onChange={handleSearch}
              sx={{ width: "350px", marginBottom: "10px" }}
            />
          </Grid>
        )}
      </Box>
      <Divider />
      {filteredTrainings && filteredTrainings.length > 0 ? (
        <STable>
          <TableBody>
            <TableRow>
              {[
                "Prénom",
                "Nom",
                "Organisation",
                "Qualification",
                "Commentaires",
                "Sessions théoriques",
                "Documents",
                "Résultats QCM",
                "Profil",
                "Mail",
                "Zuurit",
              ].map((header, index) => (
                <TableCell key={index} sx={{ width: "5%" }} align="left">
                  <STypographyItem>{header}</STypographyItem>
                </TableCell>
              ))}
            </TableRow>
            {filteredTrainings.map((t) => (
              <TrainingInProgressItem
                training={t}
                key={t.CustomerID}
                setTrainings={setTrainings}
              />
            ))}
          </TableBody>
        </STable>
      ) : (
        <STable>
          <TableBody>
            <TrainingEmptyList />
          </TableBody>
        </STable>
      )}
    </Box>
  );
}
