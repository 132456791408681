import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useAlert } from "../../../hooks/useAlert";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { PortalCustomer, PortalResponse } from "../../../api/auth/Auth.typed";
import useAuth from "../../../contexts/controllers/useAuth";
import { JwtPayload, jwtDecode } from "jwt-decode";

interface PortalLoginModalProps {
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  setCustomer: Dispatch<SetStateAction<PortalResponse | undefined>>;
}

export default function PortalLoginModal(props: PortalLoginModalProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { loginPortal, getPortalCustomerInfo } = useAuth();
  const { showAlert } = useAlert();
  const location = useLocation();

  const handleSubmit = async () => {
    if (password !== undefined && email !== null) {
      setIsLoading(true);

      const creditId = new URLSearchParams(location.search).get("creditId");

      const PortalCustomer: PortalCustomer = {
        Email: email,
        Password: password,
      };

      try {
        if (creditId != null) {
          await loginPortal(PortalCustomer, creditId).then(async (c) => {
            if (c != undefined) {
              Cookies.set("authToken", c.Token, { expires: 1 });
              await getPortalCustomerInfo(c.Token).then((cus) => {
                props.setCustomer(cus);
                showAlert("Connexion réussie !", "success");
                props.setIsLoggedIn(true);
              });
            }
          });
        } else {
          showAlert("Aucun creditId renseigné", "error");
        }
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "error");
        }
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      const decodedToken: JwtPayload = jwtDecode(authToken);
      if (decodedToken.exp) {
        const expDate = new Date(decodedToken.exp * 1000);
        if (expDate < new Date()) {
          showAlert("Session expirée !", "info");
          props.setIsLoggedIn(false);
        } else {
          getPortalCustomerInfo(authToken).then((c) => {
            props.setCustomer(c);
            props.setIsLoggedIn(true);
          });
        }
      }
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "85%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      <DialogTitle sx={{ color: "black", fontSize: "18px" }}>
        Bienvenue !
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginTop: "10px" }}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          label="Mot de passe"
          type={showPassword ? "text" : "password"}
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginTop: "10px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        {!isLoading ? (
          email !== "" && password !== "" ? (
            <Button
              onClick={handleSubmit}
              color="primary"
              sx={{ margin: "10px", width: "50%", height: "40px" }}
            >
              Se connecter
            </Button>
          ) : (
            <Button
              color="primary"
              disabled
              sx={{ margin: "10px", width: "50%", height: "40px" }}
            >
              Se connecter
            </Button>
          )
        ) : (
          <LoadingButton
            loading
            variant="contained"
            sx={{ margin: "10px", width: "50%", height: "40px" }}
          />
        )}
      </DialogActions>
    </Box>
  );
}