import { ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import CustomerEmptyItem from "./CustomerEmptyItem";
import CustomerItem from "./CustomerItem";

type CustomersListProps = {
  customers: ZekayCustomer[] | undefined;
};

export default function CustomersList(props: CustomersListProps) {
  return props.customers !== undefined && props.customers?.length > 0 ? (
    props.customers?.map((c): JSX.Element => {
      return <CustomerItem customer={c} key={c.Id} />;
    })
  ) : (
    <CustomerEmptyItem />
  );
}
