import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useAlert } from "../../../hooks/useAlert";
import SettingsComponent from "./SettingsComponents";

export default function AccountMenu() {
  const { user, logoutCallback } = React.useContext(AuthContext);
  const { showAlert } = useAlert();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logoutCallback();
      showAlert("A bientôt !", "info");
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "error");
      }
    }
  };

  /*   const handleOpenModal = () => {
    setOpenModal(true);
  }; */

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          alignItems: "center",
          textAlign: "center",
          right: 20,
        }}
      >
        <Tooltip title="Menu utilisateur">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {user !== undefined ? user.Name[0] : null}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>{user?.Name}</MenuItem>
        {/*         <Divider orientation="vertical" sx={{ backgroundColor: "black" }} />
        <MenuItem onClick={handleOpenModal}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Paramètres
        </MenuItem>
        <Divider /> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Se déconnecter
        </MenuItem>
      </Menu>
      <SettingsComponent open={openModal} onClose={handleCloseModal} />
    </React.Fragment>
  );
}
