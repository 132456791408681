import { AppConfig } from "../../contexts/AppConfigContext";
import {
  ActionLog,
  CourseTemplate,
  ExcursionsStats,
  File,
  InvoicesDate,
  Mail,
  Session,
  StatDate,
  StatsHandler,
  SystemInfos,
  Training,
  TrainingStats,
  TrainingTemplate,
  UpdateLog,
  WorkerLog,
  ZekayConfig,
  ZekayCustomer,
} from "./Zekay.typed";

export class ZekayModel {
  zekayAPI: string;

  constructor(config: AppConfig) {
    this.zekayAPI = config.zekayAPI;
  }

  async refreshZuurit(): Promise<void> {
    fetch(this.zekayAPI + "/refresh", { credentials: "include" })
      .then(async (response) => {
        if (response.status !== 204) {
          throw new Error("Refresh error");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getCustomers(): Promise<ZekayCustomer[]> {
    return fetch(this.zekayAPI + "/customers", {
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          throw new Error("Base de donnée vide");
        } else {
          throw new Error("Quelque chose s'est mal passé");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getCustomer(id: string): Promise<ZekayCustomer> {
    return fetch(this.zekayAPI + "/customers/" + id, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getProductsCsv(): Promise<string | undefined> {
    return fetch(this.zekayAPI + "/products/csv", {
      headers: {
        "content-type": "text/csv;charset=utf-8",
      },
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.text();
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getInvoicesCsv(period: InvoicesDate): Promise<string | undefined> {
    return fetch(this.zekayAPI + "/invoices/csv", {
      method: "POST",
      headers: {
        "content-type": "text/csv;charset=utf-8",
      },
      credentials: "include",
      body: JSON.stringify(period),
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.text();
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getDoneTrainings(): Promise<Training[]> {
    return fetch(this.zekayAPI + "/trainings/done", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        }
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async createTraining(training: Training): Promise<void> {
    return fetch(this.zekayAPI + "/trainings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(training),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async deleteTraining(training: Training): Promise<void> {
    return fetch(this.zekayAPI + "/trainings", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(training),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateTraining(t: Training): Promise<void> {
    await fetch(this.zekayAPI + "/trainings", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(t),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async validateUserQualification(t: Training): Promise<void> {
    await fetch(this.zekayAPI + "/qualification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(t),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateFile(file: File): Promise<void> {
    await fetch(this.zekayAPI + "/customers/file", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateTrainingFile(file: File): Promise<void> {
    await fetch(this.zekayAPI + "/trainings/file", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async deleteTrainingFile(file: File): Promise<void> {
    await fetch(this.zekayAPI + "/trainings/file", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async sendMail(mail: Mail): Promise<void> {
    return fetch(this.zekayAPI + "/customers/mail/training", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mail),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getExcursionsStatsByYear(
    date: StatsHandler
  ): Promise<ExcursionsStats[]> {
    return fetch(this.zekayAPI + "/excursions/stats/year", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(date),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Quelque chose s'est mal passé");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getExcursionsStatsByMonth(date: StatsHandler): Promise<ExcursionsStats> {
    return fetch(this.zekayAPI + "/excursions/stats/date", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(date),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Quelque chose s'est mal passé");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getStatDates(): Promise<StatDate[]> {
    return fetch(this.zekayAPI + "/dates", {
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Quelque chose s'est mal passé");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getTrainingStatsByYear(
    date: StatsHandler
  ): Promise<TrainingStats[]> {
    return fetch(this.zekayAPI + "/trainings/stats/year", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(date),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Quelque chose s'est mal passé");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }


  async getTrainingsTemplates(): Promise<TrainingTemplate[]> {
    return fetch(this.zekayAPI + "/trainings/templates", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateTrainingTemplate(tt: TrainingTemplate): Promise<void> {
    await fetch(this.zekayAPI + "/trainings/templates", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tt),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getTrainingsByCreditId(id: string): Promise<Training[]> {
    return fetch(this.zekayAPI + "/trainings/credit/" + id, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getTrainingsByStatusCreditId(
    id: string,
    status: number
  ): Promise<Training[]> {
    return fetch(
      this.zekayAPI + "/trainings/credit/" + id + "/" + String(status),
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getLogsByTable(table: string): Promise<UpdateLog> {
    return fetch(this.zekayAPI + "/logs/" + table, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getActionsLogs(): Promise<ActionLog[]> {
    return fetch(this.zekayAPI + "/logs/actions", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getWorkerLogs(): Promise<WorkerLog[]> {
    return fetch(this.zekayAPI + "/logs/worker", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getWorkerLogsByUserId(id: string): Promise<WorkerLog[]> {
    return fetch(this.zekayAPI + "/logs/worker/id/" + id, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getWorkerLogsByStatus(status: string): Promise<WorkerLog[]> {
    return fetch(this.zekayAPI + "/logs/worker/status/" + status, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getCourseTemplate(id: number): Promise<CourseTemplate> {
    return fetch(this.zekayAPI + "/courses/templates/" + id, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async createCourseTemplate(ct: CourseTemplate): Promise<void> {
    await fetch(this.zekayAPI + "/courses/templates/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ct),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateCourseTemplate(ct: CourseTemplate): Promise<void> {
    await fetch(this.zekayAPI + "/courses/templates/update", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ct),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async createSession(session: Session): Promise<void> {
    await fetch(this.zekayAPI + "/courses/session/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(session),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateSession(s: Session): Promise<void> {
    await fetch(this.zekayAPI + "/courses/session/update", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(s),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async generateSessionPDF(
    creditId: number,
    customerId: number
  ): Promise<void> {
    return fetch(
      this.zekayAPI +
        "/courses/session/generate/" +
        creditId +
        "/" +
        customerId,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getConfig(id: number): Promise<ZekayConfig> {
    return fetch(this.zekayAPI + "/config/" + id, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateConfig(c: ZekayConfig): Promise<void> {
    await fetch(this.zekayAPI + "/config", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(c),
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 204) {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getSystemInfos(): Promise<SystemInfos> {
    return fetch(this.zekayAPI + "/system/infos", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getGoogleLabels(): Promise<string[]> {
    return fetch(this.zekayAPI + "/newsletter/labels", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getQualificationsList(): Promise<string[]> {
    return fetch(this.zekayAPI + "/qualifications", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return undefined;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getNewsletterMapping(): Promise<{ [key: string]: string[] }> {
    return fetch(this.zekayAPI + "/newsletter/mapping", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 204) {
          return {};
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updateNewsletterMapping(mapping: {
    [key: string]: string[];
  }): Promise<void> {
    return fetch(this.zekayAPI + "/newsletter/mapping", {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mapping),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update the newsletter mapping");
        }
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }
}
