import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/system";
import { Divider, Hidden } from "@mui/material";
import { SSideBar } from "../../styles/StyledMUI";

type SideBarProps = {
  setPage: (page: JSX.Element) => void;
  config: {
    label: string;
    icon: JSX.Element;
    component: JSX.Element;
  }[];
};

export default function SideBar({ setPage, config }: SideBarProps) {
  return (
    <SSideBar>
      <List>
        {config.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => setPage(item.component)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <Hidden mdDown>
                <ListItemText primary={item.label} />
              </Hidden>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ position: "absolute", bottom: "0" }}>
        <Divider sx={{ width: "100%" }} />
      </Box>
    </SSideBar>
  );
}
