import React from "react";
import CardValidationItem from "./cardValidationItem";
import {
  STable,
  STypographyItem,
  STypographyTitle,
} from "../../../styles/StyledMUI";
import { Box } from "@mui/system";
import {
  CircularProgress,
  Divider,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import CardEmptyItem from "./cardEmptyList";
import { Training } from "../../../api/zekay/Zekay.typed";
import useZekay from "../../../contexts/controllers/useZekay";

export default function CardValidationList() {
  const [trainings, setTrainings] = React.useState<Training[]>();
  const [updatedAt, setUpdatedAt] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getDoneTrainings, getLogsByTable } = useZekay();

  React.useEffect(() => {
    const fetchUpdateLog = async () => {
      try {
        const log = await getLogsByTable("customers");
        if (log) {
          setUpdatedAt(log.UpdatedAt);
        }
      } catch (error) {
        console.error("Failed to fetch update log:", error);
      }
    };

    fetchUpdateLog();
  }, [getLogsByTable]);

  React.useEffect(() => {
    setIsLoading(true);
    getDoneTrainings()
      .then((t) => setTrainings(t || []))
      .finally(() => setIsLoading(false));
  }, [getDoneTrainings]);

  return (
    <>
      <Box sx={{ width: "100%", textAlign: "start" }}>
        <STypographyTitle padding={2}>Cartes à valider</STypographyTitle>
        {trainings && trainings.length > 0 && !isLoading && (
          <STypographyItem sx={{ width: "150px", marginLeft: "10px", marginBottom: "10px" }}>
            {"Total : " + trainings.length}
          </STypographyItem>
        )}
        {isLoading && (
          <Box sx={{ display: "flex", marginLeft: "10px", marginBottom: "10px", width: "150px" }}>
            <CircularProgress />
          </Box>
        )}
        <Divider />
        <STable>
          <TableBody>
            <TableRow>
              {[
                "Prénom",
                "Nom",
                "Organisation",
                "Qualification",
                "Commentaires",
                "Sessions théoriques",
                "Docs",
                "Résultats QCM",
                "Profil",
                "Zuurit",
                "Cartes",
                "Valider",
              ].map((header, index) => (
                <TableCell key={index} sx={{ width: "5%" }} align="left">
                  <STypographyItem>{header}</STypographyItem>
                </TableCell>
              ))}
            </TableRow>
            {trainings && trainings.length > 0 ? (
              trainings.map((t) => (
                <CardValidationItem
                  training={t}
                  key={t.CustomerID}
                  setTrainings={setTrainings}
                />
              ))
            ) : !isLoading ? (
              <CardEmptyItem />
            ) : null}
          </TableBody>
        </STable>
      </Box>
      <Box
        sx={{
          textAlign: "right",
          position: "fixed",
          right: 0,
          bottom: 0,
          width: "20%",
          backgroundColor: "white",
          padding: 1,
        }}
      >
        <STypographyItem>
          {updatedAt
            ? `Dernière mise à jour depuis Zuurit: ${new Date(updatedAt).toLocaleString()}`
            : "Chargement..."}
        </STypographyItem>
      </Box>
    </>
  );
}
