import React from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Card,
  Grid,
  Divider,
  Tooltip,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import { Module, Criteria } from "../../../../api/zekay/Zekay.typed";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SAddCriteria, SAvatar, SDelete } from "../../../../styles/StyledMUI";
import { Box } from "@mui/system";
import { AddCircle } from "@mui/icons-material";

interface ModuleFormProps {
  module: Module;
  onChange: (updatedModule: Module) => void;
  onDeleteModule: () => void;
}

export default function ModuleForm({
  module,
  onChange,
  onDeleteModule,
}: ModuleFormProps) {
  const [savedCustomOptions, setSavedCustomOptions] = React.useState<string[][]>([]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...module, name: e.target.value });
  };

  const handleCriteriaChange = (index: number, updatedCriteria: Criteria) => {
    const updatedCriteriaList = [...module.criteria];
    updatedCriteriaList[index] = updatedCriteria;
    onChange({ ...module, criteria: updatedCriteriaList });
  };

  const handleOptionsTypeChange = (index: number, event: SelectChangeEvent<string>) => {
    const optionsType = event.target.value;
    const updatedCriteria = [...module.criteria];

    if (optionsType === "default") {
      if (!savedCustomOptions[index]) {
        savedCustomOptions[index] = [...updatedCriteria[index].options];
        setSavedCustomOptions([...savedCustomOptions]);
      }
      updatedCriteria[index].options = ["OK", "A retravailler"];
    } else if (optionsType === "custom") {
      if (savedCustomOptions[index]) {
        updatedCriteria[index].options = [...savedCustomOptions[index]];
      } else {
        updatedCriteria[index].options = [""];
      }
    }

    onChange({ ...module, criteria: updatedCriteria });
  };

  const addOption = (index: number) => {
    const updatedCriteria = [...module.criteria];
    updatedCriteria[index].options.push("");
    onChange({ ...module, criteria: updatedCriteria });
  };

  const deleteOption = (criteriaIndex: number, optionIndex: number) => {
    const updatedCriteria = [...module.criteria];
    updatedCriteria[criteriaIndex].options.splice(optionIndex, 1);
    onChange({ ...module, criteria: updatedCriteria });
  };

  const handleOptionChange = (criteriaIndex: number, optionIndex: number, value: string) => {
    const updatedCriteria = [...module.criteria];
    updatedCriteria[criteriaIndex].options[optionIndex] = value;
    onChange({ ...module, criteria: updatedCriteria });
  };

  const handleDeleteCriteria = (index: number) => {
    const updatedCriteriaList = [...module.criteria];
    updatedCriteriaList.splice(index, 1);
    onChange({ ...module, criteria: updatedCriteriaList });
  };

  const addCriteria = () => {
    onChange({
      ...module,
      criteria: [...module.criteria, { description: "", options: ["OK", "A retravailler"], comments: true }],
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedCriteriaList = Array.from(module.criteria);
    const [reorderedItem] = updatedCriteriaList.splice(result.source.index, 1);
    updatedCriteriaList.splice(result.destination.index, 0, reorderedItem);

    onChange({ ...module, criteria: updatedCriteriaList });
  };

  return (
    <Card
      sx={{
        padding: 3,
        marginBottom: 3,
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative", // Ensure relative positioning for absolute elements inside
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          label="Nom du Module"
          value={module.name}
          onChange={handleNameChange}
          fullWidth
          margin="normal"
        />
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="criteria">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {module.criteria.map((criteria, index) => (
                <Draggable key={index} draggableId={`criteria-${index}`} index={index}>
                  {(provided) => (
                    <Card
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        padding: 2,
                        marginBottom: 2,
                        backgroundColor: "#EEEEEE",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
                        position: "relative", // Ensure relative positioning for absolute elements inside
                      }}
                    >
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            label="Description du Critère"
                            value={criteria.description}
                            onChange={(e) =>
                              handleCriteriaChange(index, { ...criteria, description: e.target.value })
                            }
                            fullWidth
                            margin="normal"
                          />
                          <FormControl fullWidth margin="normal">
                            <Select
                              value={
                                criteria.options[0] === "OK" && criteria.options[1] === "A retravailler"
                                  ? "default"
                                  : "custom"
                              }
                              onChange={(e) => handleOptionsTypeChange(index, e)}
                              displayEmpty
                            >
                              <MenuItem value="default">Par défaut: "OK / A retravailler"</MenuItem>
                              <MenuItem value="custom">Options configurables</MenuItem>
                            </Select>
                          </FormControl>

                          {criteria.options[0] !== "OK" && (
                            <Grid container spacing={2} alignItems={"center"} textAlign={"center"}>
                              {criteria.options.map((option, optIndex) => (
                                <>
                                  <Grid item xs={2} key={optIndex}>
                                    <TextField
                                      label={`Option ${optIndex + 1}`}
                                      value={option}
                                      onChange={(e) =>
                                        handleOptionChange(index, optIndex, e.target.value)
                                      }
                                      fullWidth
                                      margin="normal"
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Tooltip title="Supprimer option">
                                      <IconButton sx={{ marginRight: 10 }}>
                                        <SDelete onClick={() => deleteOption(index, optIndex)} />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </>
                              ))}
                              <Grid item xs={1}>
                                <Tooltip title="Ajouter option">
                                  <SAvatar sx={{ backgroundColor: "#EEEEEE" }}>
                                    <AddCircle sx={{ cursor: "pointer", color: "green" }} onClick={() => addOption(index)} />
                                  </SAvatar>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>

                        {/* Button to delete the criteria, positioned at the bottom-right */}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 10,
                            right: 10,
                          }}
                        >
                          <Tooltip title="Supprimer critère">
                            <IconButton>
                              <SDelete onClick={() => handleDeleteCriteria(index)} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Supprimer Module">
          <IconButton sx={{ marginRight: 2 }}>
            <SDelete onClick={onDeleteModule} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ajouter critère">
          <IconButton>
            <SAddCriteria onClick={() => addCriteria()} />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
}