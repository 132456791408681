import React from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import {
  SAvatar,
  SSave,
  STypography,
  STypographyTitle,
} from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";
import { SystemInfos, ZekayConfig } from "../../../api/zekay/Zekay.typed";
import { useAlert } from "../../../hooks/useAlert";
import StorageIcon from "@mui/icons-material/Storage";
import { JsonEditor } from "json-edit-react";

export default function GeneralSettingsPage() {
  const { getConfig, updateConfig, getSystemInfos } = useZekay();
  const [config, setConfig] = React.useState<ZekayConfig | null>(null);
  const [infos, setInfos] = React.useState<SystemInfos>();
  const { showAlert } = useAlert();

  React.useEffect(() => {
    getConfig(1)
      .then((conf) => setConfig(conf || null))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  }, []);

  React.useEffect(() => {
    getSystemInfos()
      .then((infos) => setInfos(infos))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  }, []);

  // Utilise la bonne signature pour onChange
  const handleJsonChange = ({
    currentData,
    newValue,
    currentValue,
    name,
    path,
  }: {
    currentData: any;
    newValue: any;
    currentValue: any;
    name: string | number; // Peut être un string ou un number
    path: (string | number)[];
  }) => {
    // Appliquer une transformation si nécessaire, ici on ne fait rien de particulier
    return newValue;
  };

  const setData = (updatedJson: any, field: string) => {
    setConfig((prevConfig) => ({
      ...prevConfig!,
      [field]: updatedJson,
    }));
  };

  const handleSave = () => {
    if (config) {
      updateConfig(config)
        .then(() =>
          showAlert("Configuration mise à jour avec succès", "success")
        )
        .catch((error) => {
          if (error instanceof Error) {
            showAlert(error.message, "error");
          }
        });
    }
  };

  const handleToggleMailingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (config) {
      const updatedConfig: ZekayConfig = {
        ...config,
        AutomaticMailing: event.target.checked,
      };

      if (updatedConfig) {
        setConfig(updatedConfig);

        updateConfig(updatedConfig)
          .then(() =>
            showAlert("Configuration mise à jour avec succès", "success")
          )
          .catch((error) => {
            if (error instanceof Error) {
              showAlert(error.message, "error");
            }
          });
      }
    }
  };

  const handleToggleGroupsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (config) {
      const updatedConfig: ZekayConfig = {
        ...config,
        ManageGroups: event.target.checked,
      };

      if (updatedConfig) {
        setConfig(updatedConfig);

        updateConfig(updatedConfig)
          .then(() =>
            showAlert("Configuration mise à jour avec succès", "success")
          )
          .catch((error) => {
            if (error instanceof Error) {
              showAlert(error.message, "error");
            }
          });
      }
    }
  };

  if (!config) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", padding: 3 }}>
      <Box sx={{ marginBottom: 3 }}>
        <STypographyTitle>Paramètres généraux</STypographyTitle>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Mails */}
      <Box sx={{ marginBottom: 4 }}>
        <STypography variant="h6">Mails</STypography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: 2,
          }}
        >
          <Box sx={{ margin: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={config.AutomaticMailing}
                  onChange={handleToggleMailingChange}
                />
              }
              label="Envoi automatique des emails de bienvenue"
            />
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Groups */}
      <Box sx={{ marginBottom: 4 }}>
        <STypography variant="h6">Groupes</STypography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: 2,
          }}
        >
          <Box sx={{ margin: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={config.ManageGroups}
                  onChange={handleToggleGroupsChange}
                />
              }
              label="Mise à jour automatique des groupes Google"
            />
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Google Token Editor */}
      <STypography variant="h6">Google Token</STypography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: 2,
        }}
      >
        <Box sx={{ width: "60%" }}>
          <JsonEditor
            data={config.google_token}
            setData={(newData) => setData(newData, "google_token")}
            onChange={handleJsonChange}
            maxWidth={"100%"}
            rootFontSize={"95%"}
          />
        </Box>
        <Box sx={{ margin: 2 }}>
          <SAvatar onClick={handleSave}>
            <SSave />
          </SAvatar>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Google Credentials Editor */}
      <STypography variant="h6">Google Credentials</STypography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: 2,
        }}
      >
        <Box sx={{ width: "60%" }}>
          <JsonEditor
            data={config.google_credentials}
            setData={(newData) => setData(newData, "google_credentials")}
            onChange={handleJsonChange}
            maxWidth={"100%"}
            rootFontSize={"95%"}
          />
        </Box>
        <Box sx={{ margin: 2 }}>
          <SAvatar onClick={handleSave}>
            <SSave />
          </SAvatar>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Google Spreadsheet Editor */}
      <STypography variant="h6">Google Spreadsheet (RGPD)</STypography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: 2,
        }}
      >
        <Box sx={{ width: "60%" }}>
          <JsonEditor
            data={config.google_spreadsheet}
            setData={(newData) => setData(newData, "google_spreadsheet")}
            onChange={handleJsonChange}
            maxWidth={"100%"}
            rootFontSize={"95%"}
          />
        </Box>
        <Box sx={{ margin: 2 }}>
          <SAvatar onClick={handleSave}>
            <SSave />
          </SAvatar>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Stockage */}
      <Box sx={{ marginBottom: 4 }}>
        <STypography variant="h6">Stockage</STypography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 4,
            marginTop: 2,
          }}
        >
          <StorageIcon sx={{ marginRight: 2 }} />
          <Typography>
            {infos?.UsedSpace.toFixed(2)} Go utilisés sur{" "}
            {infos?.TotalSpace.toFixed(2)} Go disponibles
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
