import React from "react";
import { AppConfigContext } from "./contexts/AppConfigContext";
import { AppConfig } from "./contexts/AppConfigContext";
import GlobalStyle from "../src/styles/globalStyles";
import CustomersComponent from "./components/training/customer/CustomersComponent";
import LoginPage from "./components/auth/LoginPage";
import ResetPasswordPage from "./components/auth/ResetPasswordPage";
import CreateInvitedUserPage from "./components/auth/CreateInvitedUserPage";
import AdminPage from "./components/admin/AdminPage";
import FinancePage from "./components/finance/FinancePage";
import SettingsPage from "./components/settings/SettingsPage";
import PortalPage from "./components/portal/PortalPage";
import MenuPage from "./components/menu/MenuPage";
import Home from "./components/Home";
import { AlertProvider } from "./contexts/AlertContext";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import { ConfirmationModalProvider } from "./contexts/ConfirmationContext";

export default function App() {
  const [appConfig, setAppConfig] = React.useState<AppConfig>(undefined!);

  React.useEffect(() => {
    fetch(`${window.location.origin}/config.json`)
      .then((response) => response.json())
      .then((data) => setAppConfig(data));
  }, []);

  return (
    <AppConfigContext.Provider value={appConfig}>
      <GlobalStyle />
      {appConfig !== undefined ? (
        <AlertProvider>
          <AuthProvider>
            <ConfirmationModalProvider>
              <Routes>
                <Route path="/reset" element={<ResetPasswordPage />} />
                <Route path="/create" element={<CreateInvitedUserPage />} />
                <Route path="/portal" element={<PortalPage />} />
                <Route path="/" element={<Home />}>
                  <Route path="training" element={<CustomersComponent />} />
                  <Route path="office" element={<AdminPage />} />
                  <Route path="finance" element={<FinancePage />} />
                  <Route path="settings" element={<SettingsPage />} />
                  <Route path="login" element={<LoginPage />} />
                  <Route path="menu" element={<MenuPage />} />
                </Route>
              </Routes>
            </ConfirmationModalProvider>
          </AuthProvider>
        </AlertProvider>
      ) : null}
    </AppConfigContext.Provider>
  );
}
