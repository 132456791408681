import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import { Box } from "@mui/system";
import {
  SAddDocument,
  SAnalytics,
  SAvatar,
  SDelete,
  SMissingFile,
  STypographyItem,
  SView,
} from "../../../styles/StyledMUI";
import { Training } from "../../../api/zekay/Zekay.typed";
import TrainingSummaryModal from "../../training/trainings/TrainingSummaryModal";

interface SheetTypeComponentProps {
  uploadFile: (event: any, type: string) => Promise<void>;
  deleteFile: (fileType: string) => Promise<void>;
  handleView: (type: string) => void;
  document: string | undefined;
  documentType: string;
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
  refreshTrainings: RefreshTrainingFunction;
}

type RefreshFunction<T> = () => Promise<T>;
type RefreshFunctionWithId<T> = (id?: string) => Promise<T>;

type RefreshTrainingFunction =
  | RefreshFunction<Training[] | undefined>
  | RefreshFunctionWithId<Training[] | undefined>;

export default function SheetTypeComponent(props: SheetTypeComponentProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [summaryOpen, setSummaryOpen] = React.useState<boolean>(false);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    props.uploadFile(e, props.documentType).then(() => setIsLoading(false));
  };

  return (
    <>
      <Table sx={{ border: "none" }}>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                width: "100%",
                border: "none",
                textAlign: "center",
                paddingTop: 1,
              }}
            >
              <STypographyItem>Fiche Suivi</STypographyItem>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                width: "100%",
                height: "auto",
                border: "none",
                margin: "auto",
              }}
            >
              {!isLoading ? (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box display="flex" alignItems="center" columnGap={2}>
                      {props.training.Sessions !== undefined ? (
                        <Tooltip title="Voir synthèse">
                          <SAvatar>
                            <SAnalytics onClick={() => setSummaryOpen(true)} />
                          </SAvatar>
                        </Tooltip>
                      ) : null}
                      <label>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          onChange={handleUpload}
                          accept="application/pdf"
                        />
                        <Tooltip title="Ajouter document">
                          <SAvatar style={{ marginRight: "15px" }}>
                            <SAddDocument />
                          </SAvatar>
                        </Tooltip>
                      </label>
                    </Box>
                    {props.document !== undefined ? (
                      <Box display="flex" alignItems="center" columnGap={2}>
                        <Tooltip title="Voir document">
                          <SAvatar>
                            <SView
                              onClick={() =>
                                props.handleView(props.documentType)
                              }
                            />
                          </SAvatar>
                        </Tooltip>
                        <Tooltip title="Supprimer document">
                          <SDelete
                            onClick={() => props.deleteFile(props.documentType)}
                          />
                        </Tooltip>
                      </Box>
                    ) : (
                      <>
                        <SMissingFile />
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <TrainingSummaryModal
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        training={props.training}
        setTrainings={props.setTrainings}
        refreshTrainings={props.refreshTrainings}
      />
    </>
  );
}
