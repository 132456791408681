import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Training } from "../../api/zekay/Zekay.typed";
import { SArrow, STypographyItem } from "../../styles/StyledMUI";
import { Collapse, Table, TableBody, TableCell, TableRow } from "@mui/material";

// Activer les plugins UTC et Timezone
dayjs.extend(utc);
dayjs.extend(timezone);

interface DatesComponentProps {
  training: Training;
  theory: Dayjs | null;
  rifap: Dayjs | null;
  isOpenDates: boolean;
}

export default function DatesComponentReadOnly({
  training,
  theory,
  rifap,
  isOpenDates,
}: DatesComponentProps) {
  return (
    <TableCell
      style={{
        paddingBottom: 0,
        paddingTop: 0,
        margin: "auto",
        border: isOpenDates ? "" : "none",
      }}
      colSpan={7}
    >
      <Collapse in={isOpenDates} timeout="auto" unmountOnExit>
        <Table sx={{ width: "100%", border: "none" }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "5%", border: "none" }} align="left">
                <SArrow />
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                }}
              >
                {training.NeedsTheory ? (
                  <>
                    <STypographyItem>Cours théorique</STypographyItem>
                    <Box display="flex" alignItems="center" columnGap={1}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                      >
                        <DatePicker
                          disableFuture
                          format="DD/MM/YYYY"
                          value={theory ? theory.utc() : null} // Affiche la date en UTC
                          disabled // Désactive le DatePicker pour lecture seule
                          sx={{
                            zIndex: "0",
                            marginTop: 1,
                            "& .MuiOutlinedInput-root": { fontSize: "12px" },
                            "& .MuiInputLabel-root": { fontSize: "13px" },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                ) : null}
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                }}
              >
                {training.NeedsRifap ? (
                  <>
                    <STypographyItem>RIFAP</STypographyItem>
                    <Box display="flex" alignItems="center" columnGap={1}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                      >
                        <DatePicker
                          disableFuture
                          format="DD/MM/YYYY"
                          value={rifap ? rifap.utc() : null} // Affiche la date en UTC
                          disabled // Désactive le DatePicker pour lecture seule
                          sx={{
                            zIndex: "0",
                            marginTop: 1,
                            "& .MuiOutlinedInput-root": { fontSize: "12px" },
                            "& .MuiInputLabel-root": { fontSize: "13px" },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
}
