import {
  Badge,
  IconButton,
  Input,
  InputAdornment,
  Link,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  Mail,
  Training,
  ZekayCustomer,
} from "../../../../api/zekay/Zekay.typed";
import {
  SAvatar,
  SBackground,
  SDate,
  SDelete,
  SFile,
  SInfo,
  SNotes,
  SOpenProfile,
  SRedirect,
  SSave,
  SSendMail,
  STypography,
  STypographyItem,
} from "../../../../styles/StyledMUI";
import useZekay from "../../../../contexts/controllers/useZekay";
import { useAlert } from "../../../../hooks/useAlert";
import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/system";
import CommentsComponent from "../../../common/CommentsComponent";
import useAuth from "../../../../contexts/controllers/useAuth";
import { formatDate } from "../../../../utils/dateTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs, { Dayjs } from "dayjs";
import ProfileForm from "../../../training/profiles/ProfileForm";
import DatesComponent from "../../../common/DatesComponent";
import DocumentsComponent from "../../../common/DocumentsComponent";

type TrainingInProgressItemProps = {
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
};

export default function TrainingInProgressItem(
  props: TrainingInProgressItemProps
) {
  const {
    sendMail,
    getCustomer,
    getTrainingsByCreditId,
    updateTraining,
    cancelTraining,
  } = useZekay();
  const { showAlert } = useAlert();
  const [profileFormOpen, setProfileFormOpen] = React.useState<boolean>(false);
  const [customerUpdated, setCustomerUpdated] = React.useState<ZekayCustomer>();
  const [customer, setCustomer] = React.useState<ZekayCustomer>();
  const [comments, setComments] = React.useState<string | undefined>(
    props.training?.Comments
  );
  const [testResult, setTestResult] = React.useState<string | undefined>(
    props.training?.TestResult
  );

  const theoryDate =
    props.training.Theory === undefined ? null : dayjs(props.training.Theory);
  const rifapDate =
    props.training.RifapDate === undefined
      ? null
      : dayjs(props.training.RifapDate);

  const [theory, setTheory] = React.useState<Dayjs | null>(theoryDate);
  const [rifap, setRifap] = React.useState<Dayjs | null>(rifapDate);
  const [isOpenComments, setIsOpenComments] = React.useState<boolean>(false);
  const [isOpenDocuments, setIsOpenDocuments] = React.useState<boolean>(false);
  const [isOpenDates, setIsOpenDates] = React.useState<boolean>(false);
  const { user } = useAuth();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const handleTestResult = (event: any) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    setTestResult(inputValue);
  };

  const handleClickOpenProfile = () => {
    getCustomer(String(customer?.Id)).then((r) => setCustomerUpdated(r));
    setProfileFormOpen(true);
  };

  const handleSendMail = () => {
    getCustomer(String(props.training?.CustomerID))
      .then((c: ZekayCustomer | undefined) => {
        if (c?.MailAddress !== undefined) {
          const m: Mail = {
            ZuuritID: c?.Id,
            FirstName: c?.FirstName,
            LastName: c?.LastName,
            MailAddress: c?.MailAddress,
            CreditID: props.training?.CreditId,
          };

          sendMail(m)
            .then(() => showAlert("Mail envoyé !", "success"))
            .catch((err) => showAlert(err.message, "error"));
        } else {
          showAlert("L'utilisateur n'a pas d'adresse mail", "error");
        }
      })
      .catch((e) => showAlert(e, "error"));
  };

  const handleDelete = () => {
    const tr: Training = {
      CustomerID: props.training?.CustomerID,
      CreditId: props.training?.CreditId,
      Comments: comments,
      Cards: props.training?.Cards,
      TestResult: props.training?.TestResult,
      Theory: props.training?.Theory,
      RifapDate: props.training?.RifapDate,
      TrainingSheet: props.training?.TrainingSheet,
      MedicalAgreement: props.training?.MedicalAgreement,
      MedicalCertificate: props.training?.MedicalCertificate,
      MedicalDisclaimer: props.training?.MedicalDisclaimer,
      Status: "Annulé",
      CreatedAt: props.training?.CreatedAt,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    cancelTraining(tr)
      .then(() => getTrainingsByCreditId(String(props.training?.CreditId)))
      .then((ts) => props.setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  const handleUpdate = () => {
    const tr: Training = {
      CustomerID: props.training?.CustomerID,
      CreditId: props.training?.CreditId,
      Status: props.training?.Status,
      Comments: comments,
      Cards: props.training?.Cards,
      TestResult: testResult,
      TrainingSheet: props.training?.TrainingSheet,
      MedicalAgreement: props.training?.MedicalAgreement,
      MedicalCertificate: props.training?.MedicalCertificate,
      MedicalDisclaimer: props.training?.MedicalDisclaimer,
      Theory: theory === null ? "" : theory.toISOString(),
      RifapDate: rifap === null ? "" : rifap.toISOString(),
      CreatedAt: props.training?.CreatedAt,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(tr)
      .then(() => getTrainingsByCreditId(String(props.training?.CreditId)))
      .then((ts) => props.setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  React.useEffect(() => {
    getCustomer(String(props.training?.CustomerID)).then(
      (c: ZekayCustomer | undefined) => setCustomer(c)
    );
  }, []);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.FirstName}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.LastName}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.Agency}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.Qualification}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <Badge
            color="secondary"
            badgeContent={comments && comments?.length > 0 ? 1 : 0}
            sx={{ zIndex: "0" }}
          >
            <IconButton onClick={() => setIsOpenComments(!isOpenComments)}>
              <SNotes />
            </IconButton>
          </Badge>
        </TableCell>
        <TableCell sx={{ width: "5%" }} padding={"normal"}>
          {props.training.NeedsTheory ? (
            <IconButton onClick={() => setIsOpenDates(!isOpenDates)}>
              <SDate />
            </IconButton>
          ) : (
            <STypographyItem>N/A</STypographyItem>
          )}
        </TableCell>
        <TableCell sx={{ width: "5%" }} padding={"normal"}>
          <IconButton onClick={() => setIsOpenDocuments(!isOpenDocuments)}>
            <SFile />
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: "10%" }} padding={"normal"}>
          <Box display="flex">
            <Input
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              onChange={handleTestResult}
              value={testResult}
            />
            <IconButton onClick={handleUpdate}>
              <SSave />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell sx={{ width: "5%" }} padding={"normal"}>
          <SAvatar>
            <SOpenProfile onClick={handleClickOpenProfile} />
          </SAvatar>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <SAvatar>
            <SSendMail onClick={handleSendMail} />
          </SAvatar>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <Link
            href={
              "https://app.zuurit.com/customers/" + props.training?.CustomerID
            }
            target="_blank"
            rel="noopener"
          >
            <SAvatar>
              <SRedirect />
            </SAvatar>
          </Link>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <Tooltip title="Annuler cette formation">
            <SDelete onClick={handleDelete} />
          </Tooltip>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <Tooltip
            title={
              <>
                <STypography>
                  Créée à :{" "}
                  {props.training?.CreatedAt !== undefined
                    ? formatDate(props.training.CreatedAt)
                    : "-"}
                </STypography>
                <STypography>
                  Mise à jour à :{" "}
                  {props.training?.UpdatedAt !== undefined
                    ? formatDate(props.training.UpdatedAt)
                    : "-"}
                </STypography>
                <STypography>
                  Mise à jour par : {props.training?.UpdatedBy}
                </STypography>
              </>
            }
          >
            <SInfo />
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            margin: "auto",
            border: "none",
          }}
          colSpan={10}
        >
          {profileFormOpen ? (
            <SBackground>
              <ProfileForm
                customer={
                  customerUpdated !== undefined ? customerUpdated : customer
                }
                setProfileFormOpen={setProfileFormOpen}
                setCustomerUpdated={setCustomerUpdated}
              />
            </SBackground>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <DatesComponent
          training={props.training}
          theory={theory}
          rifap={rifap}
          setTheory={setTheory}
          setRifap={setRifap}
          updateTraining={updateTraining}
          getTrainingsByCreditId={getTrainingsByCreditId}
          setTrainings={props.setTrainings}
          showAlert={showAlert}
          isOpenDates={isOpenDates}
        />
      </TableRow>
      <TableRow>
        <CommentsComponent
          comments={comments}
          setComments={setComments}
          handleUpdate={handleUpdate}
          isOpenComments={isOpenComments}
          setIsOpenComments={setIsOpenComments}
        />
      </TableRow>
      <TableRow>
        <DocumentsComponent
          training={props.training}
          setTrainings={props.setTrainings}
          refreshTrainings={() =>
            getTrainingsByCreditId(String(props.training.CreditId))
          }
          isOpenDocuments={isOpenDocuments}
        />
      </TableRow>
    </React.Fragment>
  );
}
