import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { SLogin, SLogo } from "../../styles/StyledMUI";
import Zekay from "../../styles/images/zekay_logo.svg";
import React from "react";
import { useAlert } from "../../hooks/useAlert";
import { LoginForm } from "../../api/auth/Auth.typed";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import ResetComponent from "./display/ResetComponent";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function LoginPage() {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { loginCallback, isLogged, user } = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    if (email !== undefined && password !== undefined) {
      setIsLoading(true);

      const form: LoginForm = {
        Email: email,
        Password: password,
      };

      try {
        await loginCallback(form);
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "error");
        }
      }

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isLogged) {
      showAlert("Bienvenue " + user?.Name + " !", "success");
      navigate("/menu");
    }
  }, [isLogged]);

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <SLogo sx={{ top: "60%" }}>
          <img src={Zekay} width="400px" height="auto" alt="Logo" />
        </SLogo>
      </Grid>
      <Grid item xs={12}>
        <SLogin>
          <TextField
            label="Email"
            variant="standard"
            sx={{ margin: "10px", width: "90%" }}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            value={email}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
          <TextField
            label="Mot de passe"
            variant="standard"
            type={showPassword ? "text" : "password"}
            sx={{ margin: "10px", width: "90%" }}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="text"
            sx={{
              margin: "2px",
              height: "auto",
              fontSize: "10px",
              display: "flex-end",
            }}
            onClick={() => setIsOpen(true)}
          >
            Mot de passe oublié ?
          </Button>
          {!isLoading ? (
            email === "" && password === "" ? (
              <Button
                variant="contained"
                disabled
                sx={{ margin: "10px", width: "50%", height: "40px" }}
              >
                Se connecter
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ margin: "10px", width: "50%", height: "40px" }}
                onClick={handleLogin}
              >
                Se connecter
              </Button>
            )
          ) : (
            <LoadingButton
              loading
              variant="contained"
              sx={{ margin: "10px", width: "50%", height: "40px" }}
            />
          )}
          {isOpen ? <ResetComponent setIsOpen={setIsOpen} /> : null}
        </SLogin>
      </Grid>
    </Grid>
  );
}
