import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TrainingInProgressList from "./inProgress/trainingInProgressList";
import TrainingCancelledList from "./cancelled/trainingCancelledList";
import TrainingDoneList from "./done/trainingDoneList";
import useZekay from "../../../contexts/controllers/useZekay";
import { STypographyItem } from "../../../styles/StyledMUI";

export default function TrainingsPage() {
  const [value, setValue] = React.useState("1");
  const { getLogsByTable } = useZekay();
  const [updatedAt, setUpdatedAt] = React.useState<string | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const fetchUpdateLog = async () => {
      try {
        const log = await getLogsByTable("customers");
        log && setUpdatedAt(log.UpdatedAt);
      } catch (error) {
        console.error("Failed to fetch update log:", error);
      }
    };

    fetchUpdateLog();
  }, [getLogsByTable]);

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="En cours" value="1" />
              <Tab label="Validées" value="2" />
              <Tab label="Annulées" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TrainingInProgressList />
          </TabPanel>
          <TabPanel value="2">
            <TrainingDoneList />
          </TabPanel>
          <TabPanel value="3">
            <TrainingCancelledList />
          </TabPanel>
        </TabContext>
      </Box>
      <Box
        style={{
          textAlign: "right",
          position: "fixed",
          right: 0,
          bottom: 0,
          width: "20%",
          backgroundColor: "white",
        }}
      >
        <STypographyItem marginRight={1}>
          {updatedAt
            ? `Dernière mise à jour depuis Zuurit: ${new Date(
                updatedAt
              ).toLocaleString()}`
            : "Chargement..."}
        </STypographyItem>
      </Box>
    </>
  );
}
