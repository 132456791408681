import React from "react";
import {
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Session } from "../../../api/zekay/Zekay.typed";
import SessionsForm from "./SessionsForm";
import {
  SAvatar,
  SEdit,
  SSchool,
  STypographyItem,
} from "../../../styles/StyledMUI";

type SessionItemProps = {
  session: Session;
  onSessionSave: () => void;
};

export default function SessionItem(props: SessionItemProps) {
  const [openForm, setOpenForm] = React.useState(false);

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSaveForm = (updatedSession: Session) => {
    props.onSessionSave(); // Rafraîchir le customer après la sauvegarde
    handleCloseForm();
  };

  return (
    <>
      <TableRow sx={{ border: "none" }}>
        <TableCell sx={{ width: "10em", border: "none" }}>
          <SSchool />
        </TableCell>
        <TableCell
          sx={{ width: "10em", border: "none" }}
        >{`Séance ${props.session.Number}`}</TableCell>
        <TableCell sx={{ width: "20em", border: "none" }}>
          {props.session.Date}
        </TableCell>
        <TableCell sx={{ width: "150em", border: "none" }}>
          {props.session.Instructor !== "" ? (
            "avec " + props.session.Instructor
          ) : (
            <STypographyItem sx={{ fontStyle: "italic" }}>
              A remplir
            </STypographyItem>
          )}
        </TableCell>
        <TableCell sx={{ width: "1em", border: "none" }}>
          <SAvatar>
            <SEdit onClick={handleOpenForm} />
          </SAvatar>
        </TableCell>
      </TableRow>

      <Dialog
        open={openForm}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleCloseForm();
          }
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{"Session " + props.session.Number}</DialogTitle>
        <DialogContent>
          <SessionsForm
            session={props.session}
            onClose={handleCloseForm}
            onSave={handleSaveForm}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
