import { Training } from "../../../api/zekay/Zekay.typed";
import { Table, TableBody } from "@mui/material";
import SessionItem from "./SessionsItem";
import SessionEmptyItem from "./SessionEmptyItem";

type SessionsListProps = {
  training: Training;
  onSessionSave: () => void; // Ajout de la prop pour rafraîchir le customer
};

export default function SessionsList(props: SessionsListProps) {
  return (
    <Table>
      <TableBody>
        {props.training.Sessions ? (
          props.training.Sessions.map((session) => (
            <SessionItem
              key={session.ID}
              session={session}
              onSessionSave={props.onSessionSave} // Passer la fonction onSessionSave
            />
          ))
        ) : (
          <SessionEmptyItem />
        )}
      </TableBody>
    </Table>
  );
}
