import React, { Dispatch, SetStateAction } from "react";
import { Collapse, Table, TableBody, TableCell, TableRow } from "@mui/material";
import DocumentTypeComponent from "./display/DocumentTypeComponent";
import { Box } from "@mui/system";
import useZekay from "../../contexts/controllers/useZekay";
import { useAlert } from "../../hooks/useAlert";
import { convertBase64 } from "../../utils/fileSaver";
import { File, Training } from "../../api/zekay/Zekay.typed";
import PdfComponent from "./PdfComponent";
import { SArrow } from "../../styles/StyledMUI";
import SheetTypeComponent from "./display/SheetTypeComponent";

type RefreshFunction<T> = () => Promise<T>;
type RefreshFunctionWithId<T> = (id?: string) => Promise<T>;

type RefreshTrainingFunction =
  | RefreshFunction<Training[] | undefined>
  | RefreshFunctionWithId<Training[] | undefined>;

interface DocumentsComponentProps {
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
  refreshTrainings: RefreshTrainingFunction;
  isOpenDocuments: boolean;
}

export default function DocumentsComponent(props: DocumentsComponentProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [document, setDocument] = React.useState<string | undefined>();
  const { updateTrainingFile, deleteTrainingFile } = useZekay();
  const { showAlert } = useAlert();

  const uploadFile = async (event: any, type: string) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    const f: File = {
      CustomerID: props.training.CustomerID,
      CreditID: props.training.CreditId,
      Type: type,
      File: base64,
    };

    try {
      await updateTrainingFile(f);
      const ts = await props.refreshTrainings();
      props.setTrainings(ts);
    } catch (error) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      }
    }
  };

  const deleteFile = async (type: string) => {
    const f: File = {
      CustomerID: props.training.CustomerID,
      CreditID: props.training.CreditId,
      Type: type,
    };

    try {
      await deleteTrainingFile(f);
      const ts = await props.refreshTrainings();
      props.setTrainings(ts);
    } catch (error) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      }
    }
  };

  const handleView = (type: string) => {
    switch (type) {
      case "sheet":
        setDocument(props.training?.TrainingSheet);
        break;
      case "agreement":
        setDocument(props.training?.MedicalAgreement);
        break;
      case "disclaimer":
        setDocument(props.training?.MedicalDisclaimer);
        break;
      case "medical":
        setDocument(props.training?.MedicalCertificate);
        break;
    }

    setIsOpen(true);
  };

  return (
    <>
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          margin: "auto",
          border: props.isOpenDocuments ? "" : "none",
        }}
        colSpan={7}
      >
        <Collapse in={props.isOpenDocuments} timeout="auto" unmountOnExit>
          <Table sx={{ width: "100%", border: "none" }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ border: "none" }} align="left">
                  <SArrow />
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                  }}
                >
                  <Box display="flex" flexDirection="row" gap={2}>
                    <SheetTypeComponent
                      document={props.training?.TrainingSheet}
                      uploadFile={uploadFile}
                      deleteFile={deleteFile}
                      handleView={handleView}
                      documentType="sheet"
                      training={props.training}
                      refreshTrainings={props.refreshTrainings} 
                      setTrainings={props.setTrainings}                    />
                    {props.training.IsSDI ? (
                      <>
                        <DocumentTypeComponent
                          document={props.training?.MedicalDisclaimer}
                          uploadFile={uploadFile}
                          deleteFile={deleteFile}
                          handleView={handleView}
                          title="Décharge de responsabilité"
                          documentType="disclaimer"
                        />
                        <DocumentTypeComponent
                          document={props.training?.MedicalAgreement}
                          uploadFile={uploadFile}
                          deleteFile={deleteFile}
                          handleView={handleView}
                          title="Décharge médicale"
                          documentType="agreement"
                        />
                      </>
                    ) : null}
                    <DocumentTypeComponent
                      document={props.training?.MedicalCertificate}
                      uploadFile={uploadFile}
                      deleteFile={deleteFile}
                      handleView={handleView}
                      title="Certificat médical"
                      documentType="medical"
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    width: "100%",
                    border: "none",
                    paddingBottom: 0,
                    paddingTop: 0,
                  }}
                >
                  {isOpen ? (
                    <PdfComponent
                      file={document as string}
                      setIsOpen={setIsOpen}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </>
  );
}
