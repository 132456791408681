import React from "react";
import CardValidationList from "./cardValidation/cardValidationList";
import SideBar from "../nav/SideBar";
import TrainingsPage from "./trainings/trainingsPage";
import { Box } from "@mui/system";
import useAuth from "../../contexts/controllers/useAuth";
import { useNavigate } from "react-router-dom";
import { SCreditCard, SSchool, STemplates } from "../../styles/StyledMUI";
import TemplatesPage from "./templates/TemplatesPages";

const sidebarConfig = [
  {
    label: "Cartes",
    icon: <SCreditCard />,
    component: <CardValidationList />,
  },
  {
    label: "Formations",
    icon: <SSchool />,
    component: <TrainingsPage />,
  },
  {
    label: "Modèles",
    icon: <STemplates />,
    component: <TemplatesPage />,
  },
];

export default function AdminPage() {
  const [page, setPage] = React.useState<JSX.Element>(
    sidebarConfig[0].component
  );
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    if (!isAdmin()) {
      navigate("/menu");
    }
  }, []);

  return (
    <Box sx={{ position: "fixed", height: "calc(100vh - 64px)" }}>
      <SideBar config={sidebarConfig} setPage={setPage} />
      <Box
        sx={{
          position: "fixed",
          top: "64px",
          left: "12%",
          right: 0,
          bottom: 0,
          overflowY: "auto",
          backgroundColor: "white",
          width: "88%",
        }}
      >
        {page}
      </Box>
    </Box>
  );
}
