import { TableCell, TableRow } from "@mui/material";
import React from "react";

export default function CardEmptyItem() {
  return (
    <React.Fragment>
      <TableRow component="tr">
        <TableCell sx={{ width: "25%" }} align="left">
          Aucune carte à valider
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
