import { TableCell, TableRow } from "@mui/material";
import React from "react";

export default function SessionEmptyItem() {
  return (
    <React.Fragment>
      <TableRow component="tr">
        <TableCell sx={{ width: "25%", border: "none" }} align="left">
          Aucun suivi en cours
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
