import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  IconButton,
  InputLabel,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { TrainingTemplate, Card } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import {
  SAvatar,
  SDelete,
  SSave,
  STypographyItem,
} from "../../../../styles/StyledMUI";
import AddIcon from "@mui/icons-material/Add";

export default function TrainingTemplateEditor() {
  const [templates, setTemplates] = React.useState<TrainingTemplate[] | null>(
    null
  );
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<TrainingTemplate | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [newCardName, setNewCardName] = React.useState<string>("");

  const { getTrainingsTemplates, updateTrainingTemplate } = useZekay();

  React.useEffect(() => {
    setIsLoading(true);
    getTrainingsTemplates()
      .then((t) => setTemplates(t || []))
      .finally(() => setIsLoading(false));
  }, [getTrainingsTemplates]);

  const handleChangeTemplate = (e: SelectChangeEvent<string>) => {
    const templateId = e.target.value;
    const template = templates?.find(
      (t) => t.CreditID.toString() === templateId
    );
    setSelectedTemplate(template || null);
  };

  const handleFieldChange = (
    field: keyof Omit<TrainingTemplate, "ID" | "CreditID">,
    value: string | boolean
  ) => {
    if (selectedTemplate) {
      setSelectedTemplate({
        ...selectedTemplate,
        [field]: value,
      });
    }
  };

  const handleAddCard = () => {
    if (newCardName.trim() !== "" && selectedTemplate) {
      const updatedCards: Card[] = [
        ...selectedTemplate.Cards.Card,
        { Organisation: newCardName },
      ];
      setSelectedTemplate({
        ...selectedTemplate,
        Cards: { Card: updatedCards },
      });
      setNewCardName("");
    }
  };

  const handleRemoveCard = (index: number) => {
    if (selectedTemplate) {
      const updatedCards = selectedTemplate.Cards.Card.filter(
        (_, i) => i !== index
      );
      setSelectedTemplate({
        ...selectedTemplate,
        Cards: { Card: updatedCards },
      });
    }
  };

  const handleSaveTemplate = () => {
    if (selectedTemplate) {
      updateTrainingTemplate(selectedTemplate)
    }
  };

  const handleOpenUrl = (url: string) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <FormControl sx={{ minWidth: "400px" }}>
          <InputLabel>Formation</InputLabel>
          <Select
            value={selectedTemplate?.CreditID.toString() || ""}
            label="Formation"
            onChange={handleChangeTemplate}
            sx={{ fontSize: "15px" }}
          >
            {templates?.map((q) => (
              <MenuItem key={q.ID} value={q.CreditID.toString()}>
                {q.Agency + " - " + q.Qualification}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              marginLeft: "10px",
              width: "150px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {selectedTemplate && (
          <SAvatar>
            <SSave onClick={handleSaveTemplate} />
          </SAvatar>
        )}
      </Box>
      <Divider />
      {selectedTemplate ? (
        <Box sx={{ marginTop: "20px", width: "100%" }}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <TextField
                value={selectedTemplate.CreditID}
                InputProps={{
                  readOnly: true,
                  sx: {
                    backgroundColor: "#f5f5f5",
                    opacity: 0.8,
                  },
                }}
                fullWidth
                label="Zuurit CreditID"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={selectedTemplate.Agency}
                onChange={(e) => handleFieldChange("Agency", e.target.value)}
                fullWidth
                label="Organisation"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={selectedTemplate.Qualification}
                onChange={(e) =>
                  handleFieldChange("Qualification", e.target.value)
                }
                fullWidth
                label="Qualification"
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={selectedTemplate.Course1Url}
                  onChange={(e) =>
                    handleFieldChange("Course1Url", e.target.value)
                  }
                  fullWidth
                  label="Cours 1"
                />
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUrl(selectedTemplate.Course1Url)}
                  sx={{ marginLeft: 2 }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={selectedTemplate.Course2Url}
                  onChange={(e) =>
                    handleFieldChange("Course2Url", e.target.value)
                  }
                  fullWidth
                  label="Cours 2"
                />
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUrl(selectedTemplate.Course2Url)}
                  sx={{ marginLeft: 2 }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={selectedTemplate.TheoryUrl}
                  onChange={(e) =>
                    handleFieldChange("TheoryUrl", e.target.value)
                  }
                  fullWidth
                  label="Notes"
                />
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUrl(selectedTemplate.TheoryUrl)}
                  sx={{ marginLeft: 2 }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={selectedTemplate.TestUrl}
                  onChange={(e) => handleFieldChange("TestUrl", e.target.value)}
                  fullWidth
                  label="QCM"
                />
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUrl(selectedTemplate.TestUrl)}
                  sx={{ marginLeft: 2 }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={selectedTemplate.MedicalAgreement}
                  onChange={(e) =>
                    handleFieldChange("MedicalAgreement", e.target.value)
                  }
                  fullWidth
                  label="D�charge m�dicale"
                />
                <IconButton
                  color="primary"
                  onClick={() =>
                    handleOpenUrl(selectedTemplate.MedicalAgreement)
                  }
                  sx={{ marginLeft: 2 }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={selectedTemplate.MedicalDisclaimer}
                  onChange={(e) =>
                    handleFieldChange("MedicalDisclaimer", e.target.value)
                  }
                  fullWidth
                  label="D�charge de responsabilit�"
                />
                <IconButton
                  color="primary"
                  onClick={() =>
                    handleOpenUrl(selectedTemplate.MedicalDisclaimer)
                  }
                  sx={{ marginLeft: 2 }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={selectedTemplate.MailTemplate}
                onChange={(e) =>
                  handleFieldChange("MailTemplate", e.target.value)
                }
                fullWidth
                label="Mail"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    value={selectedTemplate.ZTrainingId}
                    onChange={(e) =>
                      handleFieldChange("ZTrainingId", e.target.value)
                    }
                    fullWidth
                    type="number"
                    label="Zuurit TrainingID"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedTemplate.ZQualificationId}
                    onChange={(e) =>
                      handleFieldChange("ZQualificationId", e.target.value)
                    }
                    fullWidth
                    type="number"
                    label="Zuurit QualificationID"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>SDI ?</InputLabel>
                    <Select
                      value={selectedTemplate.IsSDI ? "Oui" : "Non"}
                      onChange={(e) =>
                        handleFieldChange("IsSDI", e.target.value === "Oui")
                      }
                      sx={{ fontSize: "15px" }}
                      label="SDI ?"
                    >
                      <MenuItem value="Oui">Oui</MenuItem>
                      <MenuItem value="Non">Non</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Cours th�orique ?</InputLabel>
                    <Select
                      value={selectedTemplate.NeedsTheory ? "Oui" : "Non"}
                      onChange={(e) =>
                        handleFieldChange(
                          "NeedsTheory",
                          e.target.value === "Oui"
                        )
                      }
                      sx={{ fontSize: "15px" }}
                      label="Cours th�orique ?"
                    >
                      <MenuItem value="Oui">Oui</MenuItem>
                      <MenuItem value="Non">Non</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Rifap ?</InputLabel>
                    <Select
                      value={selectedTemplate.NeedsRifap ? "Oui" : "Non"}
                      onChange={(e) =>
                        handleFieldChange(
                          "NeedsRifap",
                          e.target.value === "Oui"
                        )
                      }
                      sx={{ fontSize: "15px" }}
                      label="Rifap ?"
                    >
                      <MenuItem value="Oui">Oui</MenuItem>
                      <MenuItem value="Non">Non</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Section pour les Cards */}
            <Grid item xs={12}>
              <Divider sx={{ marginY: 2 }} />
              <Box sx={{ marginBottom: 2 }}>
                <STypographyItem>Cartes</STypographyItem>
              </Box>
              {selectedTemplate.Cards.Card.map((card, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid item xs={10}>
                    <TextField
                      value={card.Organisation}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SDelete onClick={() => handleRemoveCard(index)} />
                  </Grid>
                </Grid>
              ))}
              <Grid
                container
                spacing={2}
                sx={{ marginTop: 2 }}
                alignItems="center"
              >
                <Grid item xs={10}>
                  <TextField
                    label="Ajouter une organisation"
                    value={newCardName}
                    onChange={(e) => setNewCardName(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color="primary"
                    onClick={handleAddCard}
                    size="small"
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <STypographyItem sx={{ textAlign: "center", marginTop: "20px" }}>
          S�lectionnez un template pour le modifier.
        </STypographyItem>
      )}
    </Box>
  );
}
