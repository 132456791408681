import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import React from "react";
import TrainingList from "../trainings/TrainingList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  SAvatar,
  SBackground,
  STypography,
  SView,
} from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";
import ProfileForm from "../profiles/ProfileForm";

type CustomersItemProps = {
  customer: ZekayCustomer | undefined;
};

export default function CustomerItem(props: CustomersItemProps) {
  const { getCustomer } = useZekay();
  const [customerUpdated, setCustomerUpdated] = React.useState<ZekayCustomer>();
  const [trainingsOpen, setTrainingsOpen] = React.useState(false);
  const [profileFormOpen, setProfileFormOpen] = React.useState(false);

  const handleClickOpenProfile = () => {
    getCustomer(String(props.customer?.Id)).then((r) => setCustomerUpdated(r));
    setProfileFormOpen(true);
  };

  return (
    <Card>
      <CardContent>
        <Table size="small">
          <TableBody>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
              <TableCell sx={{ width: "1em" }}>
                <IconButton
                  aria-label="expand row"
                  onClick={() => {
                    getCustomer(String(props.customer?.Id)).then((c) =>
                      setCustomerUpdated(c)
                    );

                    setTrainingsOpen(!trainingsOpen);
                  }}
                >
                  {trainingsOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell sx={{ width: "5em" }} align="left">
                <STypography>
                  {customerUpdated !== undefined
                    ? customerUpdated.LastQualification
                    : props.customer?.LastQualification}
                </STypography>
              </TableCell>
              <TableCell sx={{ width: "200em" }} align="left">
                <STypography>
                  {props.customer?.FirstName + " " + props.customer?.LastName}
                </STypography>
              </TableCell>
              <TableCell sx={{ width: "5em" }} align="left">
                <SAvatar>
                  <SView onClick={handleClickOpenProfile} />
                </SAvatar>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
                colSpan={6}
              >
                <Collapse in={trainingsOpen} timeout="auto" unmountOnExit>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          style={{ padding: 0, border: "none" }}
                        >
                          <TrainingList
                            customer={
                              customerUpdated !== undefined
                                ? customerUpdated
                                : props.customer
                            }
                            setCustomerUpdated={setCustomerUpdated}
                            setTrainingsOpen={setTrainingsOpen}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {profileFormOpen ? (
          <SBackground>
            <ProfileForm
              customer={
                customerUpdated !== undefined ? customerUpdated : props.customer
              }
              setProfileFormOpen={setProfileFormOpen}
              setCustomerUpdated={setCustomerUpdated}
            />
          </SBackground>
        ) : null}
      </CardContent>
    </Card>
  );
}
