import React from "react";
import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAlert } from "../../../../hooks/useAlert";
import useZekay from "../../../../contexts/controllers/useZekay";
import {
  ExcursionsStats,
  StatDate,
  StatsHandler,
} from "../../../../api/zekay/Zekay.typed";
import { STypography, STypographyTitle } from "../../../../styles/StyledMUI";
import { Box } from "@mui/system";

export default function TopCustomers() {
  const [dates, setDates] = React.useState<StatDate[]>();
  const [year, setYear] = React.useState<string>("");
  const [month, setMonth] = React.useState<string[]>();
  const [monthName, setMonthName] = React.useState<string>("");
  const [monthValue, setMonthValue] = React.useState<number>(0);
  const [excursionsStatsByMonth, setExcursionsStatsByMonth] =
    React.useState<ExcursionsStats>();
  const { showAlert } = useAlert();
  const { getStatDates, getExcursionsStatsByMonth } = useZekay();

  React.useEffect(() => {
    getStatDates()
      .then((dates) => setDates(dates))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  }, []);

  React.useEffect(() => {
    if (year !== undefined && month !== undefined) {
      const date: StatsHandler = {
        Year: Number(year),
        Month: monthValue,
      };

      getExcursionsStatsByMonth(date)
        .then((stat) => setExcursionsStatsByMonth(stat))
        .catch((error) => {
          if (error instanceof Error) {
            showAlert(error.message, "error");
          }
        });
    }
  }, [year, monthValue]);

  React.useEffect(() => {
    if (dates !== undefined) {
      const monthObj = dates.find((m) => {
        return m.Year === Number(year);
      });

      setMonth(monthObj?.Month);
    }
  }, [year]);

  return (
    <React.Fragment>
      <STypographyTitle padding={2}>Top client</STypographyTitle>
      <Box display="flex" alignItems="center">
        <FormControl sx={{ width: "110px", left: "20px" }}>
          <InputLabel>
            <STypography>Année</STypography>
          </InputLabel>
          <Select
            value={String(year)}
            label="Année"
            defaultValue="Année"
            onChange={(e: SelectChangeEvent<string>) => setYear(e.target.value)}
          >
            {dates?.map((date, index) => {
              return (
                <MenuItem key={index} value={date.Year}>
                  {date.Year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "110px", left: "20px" }}>
          <InputLabel>
            <STypography>Mois</STypography>
          </InputLabel>
          <Select
            value={monthName}
            label="Mois"
            defaultValue="Mois"
            onChange={(e: SelectChangeEvent<string>) => {
              setMonthValue(Number(e.target.value));
              setMonthName(e.target.value);
            }}
          >
            {month?.map((m, index) => {
              return (
                <MenuItem key={index + 1} value={index + 1}>
                  {m}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box padding={2}>
        <ResponsiveContainer width="100%" height="auto" aspect={4}>
          <BarChart
            width={500}
            height={300}
            data={excursionsStatsByMonth?.TopCustomers}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Name" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Bar yAxisId="left" dataKey="Count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </React.Fragment>
  );
}
