import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useModalShow } from "../hooks/useConfirmationModal";

type ConfirmationModalProviderProps = {
  children: React.ReactNode;
};

export type UseModalShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

export type ModalContextType = {
  showConfirmation: (
    title: string,
    message?: string,
    element?: JSX.Element
  ) => Promise<boolean>;
};

export const ConfirmationModalContext = React.createContext<ModalContextType>(
  null!
);

export function ConfirmationModalProvider(
  props: ConfirmationModalProviderProps
) {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = useState<{
    title: string;
    message?: string;
    element?: JSX.Element;
  } | null>();
  const resolver = useRef<Function>();

  const handleShow = (
    title: string,
    message?: string,
    element?: JSX.Element
  ): Promise<boolean> => {
    setContent({
      title,
      message,
      element,
    });
    setShow(true);
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow,
  };

  const handleYes = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <Dialog open={show} onClose={onHide} fullWidth>
          <DialogTitle>
            {content.title}
            <IconButton
              onClick={onHide}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
              {content.message}
            </DialogContentText>
            {content.element}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleYes}>Continuer</Button>
            <Button onClick={handleCancel}>Annuler</Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmationModalContext.Provider>
  );
}
