import { Collapse } from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  SSearchBox,
  SSearchDrawer,
  SSearchFab,
  SSearchInput,
} from "../../styles/StyledMUI";
import { ZekayCustomer } from "../../api/zekay/Zekay.typed";
import useZekay from "../../contexts/controllers/useZekay";

type SearchFabProps = {
  searchList: ZekayCustomer[] | undefined;
  setSearchList: Dispatch<SetStateAction<ZekayCustomer[] | undefined>>;
};

function SearchFab(props: SearchFabProps) {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const { customers } = useZekay();

  React.useEffect(() => {
    searchInput.length > 2
      ? props.setSearchList(forwardList(searchInput))
      : props.setSearchList([]);
  }, [searchInput, customers]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const forwardList = (value: string) => {
    return customers?.filter((c: ZekayCustomer) => {
      return c.LastName?.toLocaleLowerCase().startsWith(
        value.toLocaleLowerCase()
      );
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <SSearchBox>
      <SSearchFab color="primary" onClick={handleExpandClick}>
        <PersonSearchIcon />
      </SSearchFab>
      <Collapse in={expanded} orientation="horizontal" unmountOnExit>
        <SSearchDrawer>
          <SSearchInput
            fullWidth
            label="Rechercher client"
            value={searchInput}
            onChange={handleChange}
          />
        </SSearchDrawer>
      </Collapse>
    </SSearchBox>
  );
}
export default SearchFab;
