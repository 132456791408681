import {
  Badge,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  Link,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  Card,
  Cards,
  Training,
  TrainingTemplate,
  ZekayCustomer,
} from "../../../api/zekay/Zekay.typed";
import {
  SAvatar,
  SBackground,
  SDate,
  SDelete,
  SFile,
  SInfo,
  SNotes,
  SOpenProfile,
  SRedirect,
  SSave,
  STypography,
  STypographyItem,
  SValidate,
} from "../../../styles/StyledMUI";
import React, { Dispatch, SetStateAction } from "react";
import useZekay from "../../../contexts/controllers/useZekay";
import { useAlert } from "../../../hooks/useAlert";
import CardOrganisationStatus from "./display/cardOrganisationStatus";
import { Box } from "@mui/system";
import CommentsComponent from "../../common/CommentsComponent";
import { formatDate } from "../../../utils/dateTime";
import dayjs from "dayjs";
import useAuth from "../../../contexts/controllers/useAuth";
import ProfileForm from "../../training/profiles/ProfileForm";
import DatesComponent from "../../common/DatesComponent";
import DocumentsComponent from "../../common/DocumentsComponent";

type CardValidationItemProps = {
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
};

export default function CardValidationItem(props: CardValidationItemProps) {
  const [customer, setCustomer] = React.useState<ZekayCustomer>();
  const {
    getCustomer,
    validateUserQualification,
    qualifications,
    getDoneTrainings,
    updateTraining,
    cancelTraining,
  } = useZekay();
  const { showAlert } = useAlert();
  const [qualification, setQualification] = React.useState<TrainingTemplate>();
  const [profileFormOpen, setProfileFormOpen] = React.useState(false);
  const [customerUpdated, setCustomerUpdated] = React.useState<ZekayCustomer>();
  const [comments, setComments] = React.useState<string | undefined>(
    props.training?.Comments
  );
  const [cards, setCards] = React.useState<Card[]>([]);
  const [testResult, setTestResult] = React.useState<string | undefined>(
    props.training?.TestResult
  );
  const [isOpenComments, setIsOpenComments] = React.useState<boolean>(false);
  const [isOpenDocuments, setIsOpenDocuments] = React.useState<boolean>(false);
  const [isOpenDates, setIsOpenDates] = React.useState<boolean>(false);
  const { user } = useAuth();

  const [theory, setTheory] = React.useState<dayjs.Dayjs | null>(
    props.training.Theory ? dayjs(props.training.Theory) : null
  );
  const [rifap, setRifap] = React.useState<dayjs.Dayjs | null>(
    props.training.RifapDate ? dayjs(props.training.RifapDate) : null
  );

  const handleClickOpenProfile = () => {
    getCustomer(String(customer?.Id)).then((r) => setCustomerUpdated(r));
    setProfileFormOpen(true);
  };

  React.useEffect(() => {
    getCustomer(String(props.training?.CustomerID)).then(
      (c: ZekayCustomer | undefined) => setCustomer(c)
    );

    qualifications?.find((q) => {
      if (q.CreditID === props.training?.CreditId) {
        return setQualification(q);
      }
    });
  }, []);

  const handleTestResult = (event: any) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    setTestResult(inputValue);
  };

  const handleUpdate = () => {
    const tr: Training = {
      CustomerID: props.training?.CustomerID,
      CreditId: props.training?.CreditId,
      Status: props.training?.Status,
      Comments: comments,
      Cards: props.training?.Cards,
      TestResult: testResult,
      Theory: theory ? theory.toISOString() : "",
      RifapDate: rifap ? rifap.toISOString() : "",
      TrainingSheet: props.training?.TrainingSheet,
      MedicalAgreement: props.training?.MedicalAgreement,
      MedicalCertificate: props.training?.MedicalCertificate,
      MedicalDisclaimer: props.training?.MedicalDisclaimer,
      CreatedAt: props.training?.CreatedAt,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(tr)
      .then(() => getDoneTrainings())
      .then((ts) => props.setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  const handleSave = () => {
    const cs: Cards = {
      Card: cards,
    };

    const tr: Training = {
      CustomerID: props.training?.CustomerID,
      CreditId: props.training?.CreditId,
      Status: props.training?.Status,
      Theory: props.training.Theory,
      RifapDate: props.training.RifapDate,
      TrainingSheet: props.training?.TrainingSheet,
      MedicalAgreement: props.training?.MedicalAgreement,
      MedicalCertificate: props.training?.MedicalCertificate,
      MedicalDisclaimer: props.training?.MedicalDisclaimer,
      Comments: comments,
      Cards: cs,
      CreatedAt: props.training?.CreatedAt,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(tr)
      .then(() => getDoneTrainings())
      .then((ts) => props.setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  const handleValidate = async () => {
    const tr: Training = {
      CustomerID: props.training?.CustomerID,
      CreditId: props.training?.CreditId,
      Status: "Validé",
      Comments: props.training.Comments,
      Cards: props.training?.Cards,
      TestResult: props.training?.TestResult,
      Theory: props.training?.Theory,
      RifapDate: props.training?.RifapDate,
      TrainingSheet: props.training?.TrainingSheet,
      MedicalAgreement: props.training?.MedicalAgreement,
      MedicalCertificate: props.training?.MedicalCertificate,
      MedicalDisclaimer: props.training?.MedicalDisclaimer,
      CreatedAt: props.training?.CreatedAt,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
      ZTrainingId: qualification?.ZTrainingId,
      ZQualificationId: qualification?.ZQualificationId,
    };

    validateUserQualification(tr)
      .then(() => {
        getDoneTrainings().then((ts) => props.setTrainings(ts));
      })
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  const handleDelete = () => {
    const tr: Training = {
      CustomerID: props.training?.CustomerID,
      CreditId: props.training?.CreditId,
      Comments: comments,
      Cards: props.training?.Cards,
      TestResult: props.training?.TestResult,
      Theory: props.training?.Theory,
      RifapDate: props.training?.RifapDate,
      TrainingSheet: props.training?.TrainingSheet,
      MedicalAgreement: props.training?.MedicalAgreement,
      MedicalCertificate: props.training?.MedicalCertificate,
      MedicalDisclaimer: props.training?.MedicalDisclaimer,
      Status: "Annulé",
      CreatedAt: props.training?.CreatedAt,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    cancelTraining(tr)
      .then(() => getDoneTrainings())
      .then((ts) => props.setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.FirstName}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.LastName}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.Agency}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.Qualification}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "3%" }} align="left">
          <Badge
            color="secondary"
            badgeContent={comments && comments?.length > 0 ? 1 : 0}
            sx={{ zIndex: "0" }}
          >
            <IconButton onClick={() => setIsOpenComments(!isOpenComments)}>
              <SNotes />
            </IconButton>
          </Badge>
        </TableCell>
        <TableCell sx={{ width: "5%" }} padding={"normal"}>
          {props.training.NeedsTheory ? (
            <IconButton onClick={() => setIsOpenDates(!isOpenDates)}>
              <SDate />
            </IconButton>
          ) : (
            <STypographyItem>N/A</STypographyItem>
          )}
        </TableCell>
        <TableCell sx={{ width: "3%" }} padding={"normal"}>
          <IconButton onClick={() => setIsOpenDocuments(!isOpenDocuments)}>
            <SFile />
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: "9%" }} padding={"normal"}>
          <Box display="flex">
            <Input
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              onChange={handleTestResult}
              value={testResult}
            />
            <IconButton onClick={handleUpdate}>
              <SSave />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell sx={{ width: "3%" }} padding={"normal"}>
          <SAvatar>
            <SOpenProfile onClick={handleClickOpenProfile} />
          </SAvatar>
        </TableCell>
        <TableCell sx={{ width: "3%" }} align="left">
          <Link
            href={
              "https://app.zuurit.com/customers/" + props.training?.CustomerID
            }
            target="_blank"
            rel="noopener"
          >
            <SAvatar>
              <SRedirect />
            </SAvatar>
          </Link>
        </TableCell>
        <TableCell sx={{ width: "3%" }} align="left">
          <FormGroup>
            {props.training?.Cards?.Card !== null
              ? props.training?.Cards?.Card.map((c: Card, index: number) => {
                  return (
                    <CardOrganisationStatus
                      key={index}
                      index={index}
                      cardOrganisation={c.Organisation}
                      cardStatus={c.Status !== undefined ? c.Status : false}
                      cards={cards}
                      setCards={setCards}
                      handleSave={handleSave}
                    />
                  );
                })
              : null}
          </FormGroup>
        </TableCell>
        <TableCell sx={{ width: "3%" }} padding={"normal"}>
          <SAvatar>
            <SValidate onClick={() => handleValidate()} />
          </SAvatar>
        </TableCell>
        <TableCell sx={{ width: "3%" }} align="left">
          <Tooltip title="Annuler cette formation">
            <SDelete onClick={handleDelete} />
          </Tooltip>
        </TableCell>
        <TableCell sx={{ width: "3%" }} align="left">
          <Tooltip
            title={
              <>
                <STypography>
                  Créée à :{" "}
                  {props.training?.CreatedAt !== undefined
                    ? formatDate(props.training.CreatedAt)
                    : "-"}
                </STypography>
                <STypography>
                  Mise à jour à :{" "}
                  {props.training?.UpdatedAt !== undefined
                    ? formatDate(props.training.UpdatedAt)
                    : "-"}
                </STypography>
                <STypography>
                  Mise à jour par : {props.training?.UpdatedBy}
                </STypography>
              </>
            }
          >
            <SInfo />
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            margin: "auto",
            border: "none",
          }}
          colSpan={10}
        >
          {profileFormOpen ? (
            <SBackground>
              <ProfileForm
                customer={
                  customerUpdated !== undefined ? customerUpdated : customer
                }
                setProfileFormOpen={setProfileFormOpen}
                setCustomerUpdated={setCustomerUpdated}
              />
            </SBackground>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <CommentsComponent
          comments={comments}
          setComments={setComments}
          handleUpdate={handleUpdate}
          isOpenComments={isOpenComments}
          setIsOpenComments={setIsOpenComments}
        />
      </TableRow>
      <TableRow>
        <DatesComponent
          training={props.training}
          theory={theory}
          rifap={rifap}
          setTheory={setTheory}
          setRifap={setRifap}
          updateTraining={updateTraining}
          getTrainingsByCreditId={getDoneTrainings}
          setTrainings={props.setTrainings}
          showAlert={showAlert}
          isOpenDates={isOpenDates}
        />
      </TableRow>
      <TableRow>
        <DocumentsComponent
          training={props.training}
          setTrainings={props.setTrainings}
          refreshTrainings={getDoneTrainings}
          isOpenDocuments={isOpenDocuments}
        />
      </TableRow>
    </React.Fragment>
  );
}
