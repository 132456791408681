import { Box, Link, Avatar, IconButton, Typography } from "@mui/material";
import { Assignment } from "@mui/icons-material";

interface TrainingTestProps {
  testUrl: string | undefined;
}

export default function TrainingTest(props: TrainingTestProps) {
  const renderIconButton = (
    url: string,
    icon: React.ReactElement,
    description: string
  ) => (
    <Box textAlign={"center"}>
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        sx={{ color: "inherit", textDecoration: "none" }}
      >
        <IconButton
          sx={{
            bgcolor: "primary.main",
            width: 56,
            height: 56,
            margin: 1,
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Avatar
            sx={{
              bgcolor: "transparent",
              width: 24,
              height: 24,
            }}
          >
            {icon}
          </Avatar>
        </IconButton>
        <Typography fontSize={"13px"}>{description}</Typography>
      </Link>
    </Box>
  );

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {props.testUrl != undefined ? (
          <Box
            sx={{
              display: "row",
              textAlign: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            {renderIconButton(
              props.testUrl,
              <Assignment sx={{ color: "white" }} />,
              "QCM"
            )}
            <Typography fontSize={"12px"}>
              mot de passe : <i>escapadeqcm</i>
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
