import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import React from "react";
import AccountMenu from "./display/AccountMenu";
import Zekay from "../../styles/images/zekay_icon.svg";

function NavBar() {
  const navigate = useNavigate();
  const { isLogged } = React.useContext(AuthContext);

  const handleLogoClick = () => {
    navigate("/menu");
  };

  return (
    <AppBar
      position="fixed"
      component="nav"
      sx={{ backgroundColor: "#000", height: "64px" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={Zekay}
            width="auto"
            height="20px"
            alt="Logo"
            style={{ marginRight: "10px" }}
          />
          {isLogged ? (
            <Typography
              component="div"
              variant="h6"
              sx={(theme) => ({
                color: "white",
                [theme.breakpoints.up("xs")]: {
                  mr: 1,
                  fontFamily: "monospace",
                  fontSize: "15px",
                  letterSpacing: ".1rem",
                },
                [theme.breakpoints.up("sm")]: {
                  mr: 1,
                  fontFamily: "monospace",
                  fontSize: "20px",
                  letterSpacing: ".1rem",
                },
              })}
              onClick={handleLogoClick}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Zékay
            </Typography>
          ) : (
            <Typography
              component="div"
              variant="h6"
              sx={(theme) => ({
                color: "white",
                [theme.breakpoints.up("xs")]: {
                  mr: 1,
                  fontFamily: "monospace",
                  fontSize: "15px",
                  letterSpacing: ".1rem",
                },
                [theme.breakpoints.up("sm")]: {
                  mr: 1,
                  fontFamily: "monospace",
                  fontSize: "20px",
                  letterSpacing: ".1rem",
                },
              })}
            >
              {" "}
              Zékay
            </Typography>
          )}
          {isLogged ? <AccountMenu /> : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
