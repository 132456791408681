import React from "react";
import {
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { Session } from "../../../api/zekay/Zekay.typed";
import { SAvatar, SClose, SSave } from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";
import { useAlert } from "../../../hooks/useAlert";
import useAuth from "../../../contexts/controllers/useAuth";

type SessionsFormProps = {
  session: Session;
  onClose: () => void;
  onSave: (updatedSession: Session) => void;
};

export default function SessionsForm({
  session,
  onClose,
  onSave,
}: SessionsFormProps) {
  const initialFormState = React.useMemo(() => session.Form, [session]);
  const [form, setForm] = React.useState(initialFormState);
  const [diveSite, setDiveSite] = React.useState(session.DiveSite);
  const [diveTime, setDiveTime] = React.useState(session.DiveTime);
  const [diveDepth, setDiveDepth] = React.useState(session.DiveDepth);
  const [globalComment, setGlobalComment] = React.useState(
    session.GlobalComment
  );
  const { showAlert } = useAlert();
  const { user } = useAuth();

  const [errors, setErrors] = React.useState({
    diveSite: false,
    diveTime: false,
    diveDepth: false,
  });

  const { updateSession } = useZekay();

  React.useEffect(() => {
    const sortedForm = { ...form };
    sortedForm.modules = sortedForm.modules.map((module: any) => ({
      ...module,
      criteria: sortCriteria(module.criteria),
    }));
    setForm(sortedForm);
  }, []);

  const handleCheckboxChange = (
    moduleIndex: number,
    criteriaIndex: number,
    option: string
  ) => {
    const updatedForm = { ...form };
    const selectedOptions =
      updatedForm.modules[moduleIndex].criteria[criteriaIndex].status || [];

    if (selectedOptions.includes(option)) {
      updatedForm.modules[moduleIndex].criteria[criteriaIndex].status =
        selectedOptions.filter((opt: string) => opt !== option);
    } else {
      updatedForm.modules[moduleIndex].criteria[criteriaIndex].status = [
        ...selectedOptions,
        option,
      ];
    }

    setForm(updatedForm);
  };

  const handleRadioChange = (
    moduleIndex: number,
    criteriaIndex: number,
    value: string
  ) => {
    const updatedForm = { ...form };
    updatedForm.modules[moduleIndex].criteria[criteriaIndex].status = [value];
    setForm(updatedForm);
  };

  const handleCommentChange = (
    moduleIndex: number,
    criteriaIndex: number,
    value: string
  ) => {
    const updatedForm = { ...form };
    updatedForm.modules[moduleIndex].criteria[criteriaIndex].comments_value =
      value;
    setForm(updatedForm);
  };

  const handleSave = () => {
    const newErrors = {
      diveSite: !diveSite,
      diveTime: !diveTime || diveTime <= 0,
      diveDepth: !diveDepth || diveDepth <= 0,
    };
    setErrors(newErrors);

    const hasError = Object.values(newErrors).some((error) => error);

    if (!hasError) {
      const updatedSession = {
        ...session,
        Form: form,
        DiveSite: diveSite,
        DiveTime: diveTime,
        DiveDepth: diveDepth,
        GlobalComment: globalComment,
        Instructor: user?.Name,
      };
      updateSession(updatedSession).then(() => {
        onSave(updatedSession);
        onClose();
      });
    } else {
      showAlert("Les paramètres de plongée sont vides", "error");
    }
  };

  const handleCancel = () => {
    setForm(initialFormState);
    onClose();
  };

  // Fonction pour trier les critères : non rempli, orange, vert
  const sortCriteria = (criteria: any[]) => {
    return criteria.sort((a, b) => {
      const getPriority = (criteria: any) => {
        const selectedOptions = criteria.status || [];
        const isSingleChoice =
          criteria.options.length === 2 &&
          criteria.options.includes("OK") &&
          criteria.options.includes("A retravailler");

        if (isSingleChoice) {
          if (selectedOptions.length === 0) return 1; // Non rempli
          return selectedOptions.includes("OK") ? 3 : 2; // Vert pour "OK", Orange pour "A retravailler"
        } else {
          if (selectedOptions.length === 0) return 1; // Non rempli
          return selectedOptions.length === criteria.options.length ? 3 : 2; // Vert si tout est rempli, Orange sinon
        }
      };

      return getPriority(a) - getPriority(b);
    });
  };

  // Fonction pour déterminer la couleur et la visibilité de la barre
  const getBarColor = (criteria: any) => {
    const selectedOptions = criteria.status || [];
    const isSingleChoice =
      criteria.options.length === 2 &&
      criteria.options.includes("OK") &&
      criteria.options.includes("A retravailler");

    if (isSingleChoice) {
      if (selectedOptions.length === 0) return "none";
      return selectedOptions.includes("OK") ? "green" : "orange";
    } else {
      if (selectedOptions.length === 0) return "none";
      return selectedOptions.length === criteria.options.length
        ? "green"
        : "orange";
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 64,
        left: 0,
        width: "100vw",
        height: "calc(100vh - 64px)",
        zIndex: "199",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "90%",
          overflow: "auto",
          borderRadius: "10px",
          padding: "1rem",
          zIndex: "200",
          backgroundColor: "white",
          textAlign: "start",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {"Séance " + session.Number}
        </Typography>

        {/* Champs pour DiveSite, DiveTime et DiveDepth */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <TextField
              label="Site de plongée"
              fullWidth
              value={diveSite}
              onChange={(e) => setDiveSite(e.target.value)}
              error={errors.diveSite}
              helperText={errors.diveSite ? "Ce champ est requis" : ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Profondeur de plongée (mètres)"
              fullWidth
              type="number"
              value={diveDepth === 0 ? "" : diveDepth}
              onChange={(e) => setDiveDepth(Number(e.target.value))}
              error={errors.diveDepth}
              helperText={errors.diveDepth ? "Ce champ est requis" : ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Temps de plongée (minutes)"
              fullWidth
              type="number"
              value={diveTime === 0 ? "" : diveTime}
              onChange={(e) => setDiveTime(Number(e.target.value))}
              error={errors.diveTime}
              helperText={errors.diveTime ? "Ce champ est requis" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Commentaire général"
              fullWidth
              value={globalComment}
              onChange={(e) => setGlobalComment(e.target.value)}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        {form.modules.map((module: any, moduleIndex: number) => (
          <Box key={moduleIndex} mb={4}>
            <Typography variant="h6" gutterBottom>
              {module.name}
            </Typography>

            {module.criteria.map((criteria: any, criteriaIndex: number) => {
              const selectedOptions = criteria.status || [];
              const isSingleChoice =
                criteria.options.length === 2 &&
                criteria.options.includes("OK") &&
                criteria.options.includes("A retravailler");

              return (
                <Box
                  key={criteriaIndex}
                  mb={3}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  {/* Barre verticale */}
                  <Box
                    sx={{
                      width: "5px",
                      height: "100px",
                      backgroundColor: getBarColor(criteria),
                      marginRight:
                        getBarColor(criteria) === "none" ? "0px" : "20px",
                      display:
                        getBarColor(criteria) === "none" ? "none" : "block",
                    }}
                  />

                  {/* Contenu du critère */}
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      {criteria.description}
                    </Typography>

                    <FormControl component="fieldset" fullWidth>
                      {isSingleChoice ? (
                        <RadioGroup
                          row
                          value={selectedOptions[0] || ""}
                          onChange={(e) =>
                            handleRadioChange(
                              moduleIndex,
                              criteriaIndex,
                              e.target.value
                            )
                          }
                        >
                          {criteria.options.map(
                            (option: string, optionIndex: number) => (
                              <FormControlLabel
                                key={optionIndex}
                                value={option}
                                control={<Radio />}
                                label={option}
                              />
                            )
                          )}
                        </RadioGroup>
                      ) : (
                        <FormGroup row>
                          {criteria.options.map(
                            (option: string, optionIndex: number) => (
                              <FormControlLabel
                                key={optionIndex}
                                control={
                                  <Checkbox
                                    checked={selectedOptions.includes(option)}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        moduleIndex,
                                        criteriaIndex,
                                        option
                                      )
                                    }
                                  />
                                }
                                label={option}
                              />
                            )
                          )}
                        </FormGroup>
                      )}
                    </FormControl>

                    {criteria.comments && (
                      <TextField
                        label="Commentaires"
                        fullWidth
                        multiline
                        rows={1}
                        value={criteria.comments_value || ""}
                        onChange={(e) =>
                          handleCommentChange(
                            moduleIndex,
                            criteriaIndex,
                            e.target.value
                          )
                        }
                        sx={{ mt: 2 }}
                      />
                    )}
                  </Box>
                </Box>
              );
            })}

            <Divider sx={{ my: 2 }} />
          </Box>
        ))}
      </Box>

      {/* Barre de boutons fixe en bas */}
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "100%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "100px",
          overflow: "auto",
          borderRadius: "10px",
          padding: "1rem",
          zIndex: "200",
          backgroundColor: "white",
          textAlign: "start",
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <SAvatar>
              <SSave sx={{ color: "white" }} onClick={handleSave} />
            </SAvatar>
          </Grid>
          <Grid item>
            <SAvatar>
              <SClose sx={{ color: "white" }} onClick={handleCancel} />
            </SAvatar>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
