import { Box, Modal, MenuItem, Typography, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

type SettingsComponentProps = {
  open: boolean;
  onClose: () => void;
};

export default function SettingsComponent(props: SettingsComponentProps) {
  const [selectedMenu, setSelectedMenu] = React.useState<string | null>(null);

  const handleMenuClick = (menu: string) => {
    setSelectedMenu(menu);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          height: "80%",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          position: "relative", // Position relative to place the close button
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={props.onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10, // Ensure the button appears on top
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Left Column - Menu */}
        <Box sx={{ flex: 1, borderRight: "1px solid #ddd", paddingRight: 2 }}>
          <Typography variant="h6" gutterBottom>
            Paramètres
          </Typography>
          <MenuItem onClick={() => handleMenuClick("logs")}>Logs</MenuItem>
        </Box>

        {/* Right Column - Content */}
        <Box sx={{ flex: 3, paddingLeft: 2 }}>
          {selectedMenu === "logs" && (
            <>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
