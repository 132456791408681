import { Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";

type GdprComponentProps = {
  gdpr: boolean | undefined;
  setGdpr: Dispatch<SetStateAction<boolean>>;
};

export default function GdprComponent(props: GdprComponentProps) {
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setGdpr(event.target.checked);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ color: "black", marginBottom: 1 }}>
        Voulez-vous recevoir nos communications ?
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "black", marginRight: 2 }}>Non</Typography>
        <Switch
          checked={props.gdpr || false}
          onChange={handleSwitchChange}
          color="primary"
        />
        <Typography sx={{ color: "black", marginLeft: 2 }}>Oui</Typography>
      </Box>
    </Box>
  );
}