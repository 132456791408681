import React, { Dispatch, SetStateAction } from "react";
import {
  SAddPhoto,
  SAvatar,
  SDownloadPhoto,
  SPhoto,
  SPhotoContainer,
} from "../../../../styles/StyledMUI";
import { ZekayCustomer, File } from "../../../../api/zekay/Zekay.typed";
import { resizeFile, saveAs } from "../../../../utils/fileSaver";
import { CircularProgress, Tooltip } from "@mui/material";
import useZekay from "../../../../contexts/controllers/useZekay";

const empty = require("../../../../styles/images/noimage.png");

interface PhotoComponentProps {
  customer: ZekayCustomer | undefined;
  setCustomerUpdated: Dispatch<SetStateAction<ZekayCustomer | undefined>>;
}

export default function PhotoComponent(props: PhotoComponentProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getCustomer, updateFile } = useZekay();

  const uploadImage = async (event: any) => {
    setIsLoading(true);
    const file = event.target.files[0];
    const base64 = await resizeFile(file);

    const form: File = {
      CustomerID: props.customer?.Id,
      Type: "photo",
      File: base64,
    };

    await updateFile(form)
      .then(() =>
        setTimeout(
          () =>
            getCustomer(String(props.customer?.Id)).then((r) =>
              props.setCustomerUpdated(r)
            ),
          1000
        )
      )
      .finally(() => setIsLoading(false));
  };

  const downloadImage = async () => {
    if (props.customer !== undefined) {
      saveAs(
        props.customer.Photo,
        props.customer.FirstName + "_" + props.customer.LastName
      );
    }
  };

  return !isLoading ? (
    <SPhotoContainer sx={{ right: "40%" }}>
      <SPhoto
        src={
          props.customer?.Photo !== undefined ? props.customer?.Photo : empty
        }
        alt="photo"
      />
      <label>
        <input
          style={{ display: "none" }}
          type="file"
          onChange={uploadImage}
          accept="image/*"
        />
        <Tooltip title="Ajouter photo">
          <SAvatar
            sx={{
              position: "absolute",
              bottom: "5%",
              right: 0,
            }}
          >
            <SAddPhoto />
          </SAvatar>
        </Tooltip>
      </label>
      {props.customer?.Photo !== undefined ? (
        <Tooltip title="Télécharger photo">
          <SAvatar
            sx={{
              position: "absolute",
              bottom: "5%",
              backgroundColor: "green",
            }}
          >
            <SDownloadPhoto onClick={downloadImage} />
          </SAvatar>
        </Tooltip>
      ) : null}
    </SPhotoContainer>
  ) : (
    <CircularProgress
      sx={{
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
}
