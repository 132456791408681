import { Checkbox, FormControlLabel } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { Card } from "../../../../api/zekay/Zekay.typed";
import { STypographyItem } from "../../../../styles/StyledMUI";

type CardOrganisationStatusProps = {
  index: number;
  cardOrganisation: string;
  cardStatus: boolean;
  cards: Card[];
  setCards: Dispatch<SetStateAction<Card[]>>;
  handleSave: () => void;
};

export default function CardOrganisationStatus(
  props: CardOrganisationStatusProps
) {
  const [status, setStatus] = React.useState<boolean>(props.cardStatus);

  const handleChange = () => {
    setStatus((prevStatus) => !prevStatus);
    const updatedCards = [...props.cards]; // Créez une copie du tableau existant
    updatedCards[props.index].Status = !status;
    props.setCards(updatedCards);
    props.handleSave();
  };

  const updateCards = (updatedCard: Card, updatedIndex: number) => {
    props.setCards((cards: any) => {
      const updatedCards = cards;
      updatedCards[updatedIndex] = updatedCard;
      return updatedCards;
    });
  };

  React.useEffect(() => {
    const card: Card = {
      Organisation: props.cardOrganisation,
      Status: status,
    };
    updateCards(card, props.index);
  }, []);

  return (
    <React.Fragment>
      <FormControlLabel
        control={<Checkbox checked={status} onChange={handleChange} />}
        label={
          <STypographyItem>
            {props.cardOrganisation.toLocaleUpperCase()}
          </STypographyItem>
        }
      />
    </React.Fragment>
  );
}
