import React, { ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertColor } from "@mui/material";

interface AlertProviderProps {
  children: ReactNode;
}

interface AlertContextType {
  showAlert: (newMessage: string, newSeverity: AlertColor | undefined) => void;
}

export const AlertContext = React.createContext<AlertContextType>(null!);

export function AlertProvider(props: AlertProviderProps) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<AlertColor | undefined>(
    undefined
  );

  const showAlert = (
    newMessage: string,
    newSeverity: AlertColor | undefined
  ) => {
    setMessage(newMessage);
    setSeverity(newSeverity);
    setOpen(true);
  };

  const hideAlert = () => {
    setOpen(false);
  };

  const contextValue = {
    showAlert,
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {props.children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={hideAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
}
