import { SLogo } from "../../../styles/StyledMUI";
import Escapade from "../../../styles/images/logo_escapade-long-plein_RVB_Blanc.svg";

function CustomerEmptyItem() {
  return (
    <SLogo>
      <img src={Escapade} width="300px" height="auto" alt="Logo" />
    </SLogo>
  );
}

export default CustomerEmptyItem;
