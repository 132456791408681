import React from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useAuth from "../../contexts/controllers/useAuth";
import SideBar from "../nav/SideBar";
import { SInvoice, SProduct, SStats } from "../../styles/StyledMUI";
import ProductComponent from "./products/ProductsComponent";
import InvoicesComponent from "./invoices/InvoicesComponent";
import StatsComponent from "./stats/StatsComponent";

const sidebarConfig = [
  {
    label: "Produits",
    icon: <SProduct />,
    component: <ProductComponent />,
  },
  {
    label: "Factures",
    icon: <SInvoice />,
    component: <InvoicesComponent />,
  },
  {
    label: "Statistiques",
    icon: <SStats />,
    component: <StatsComponent />,
  },
];

export default function FinancePage() {
  const [page, setPage] = React.useState<JSX.Element>(
    sidebarConfig[0].component
  );
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    if (!isAdmin()) {
      navigate("/menu");
    }
  }, [isAdmin, navigate]);

  return (
    <Box sx={{ position: "fixed", height: "calc(100vh - 64px)" }}>
      <SideBar config={sidebarConfig} setPage={setPage} />
      <Box
        sx={{
          position: "fixed",
          top: "64px",
          left: "12%",
          right: 0,
          bottom: 0,
          overflowY: "auto",
          backgroundColor: "white",
          width: "88%",
        }}
      >
        {page}
      </Box>
    </Box>
  );
}
