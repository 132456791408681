import {
  Box,
  Link,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import { School, Description } from "@mui/icons-material";

interface TrainingDocsProps {
  course1: string | undefined;
  course2: string | undefined;
  theoryUrl: string | undefined;
}

export default function TrainingDocs(props: TrainingDocsProps) {

  const renderIconButton = (
    url: string,
    icon: React.ReactElement,
    description: string
  ) => (
    <Box textAlign={"center"}>
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        sx={{ color: "inherit", textDecoration: "none" }}
      >
        <IconButton
          sx={{
            bgcolor: "primary.main",
            width: 56,
            height: 56,
            margin: 1,
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Avatar
            sx={{
              bgcolor: "transparent",
              width: 24,
              height: 24,
            }}
          >
            {icon}
          </Avatar>
        </IconButton>
        <Typography fontSize={"13px"}>{description}</Typography>
      </Link>
    </Box>
  );

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {props.course1 != undefined
          ? renderIconButton(
              props.course1,
              <School sx={{ color: "white" }} />,
              "Cours Escapade"
            )
          : null}
        {props.course2 != undefined
          ? renderIconButton(
              props.course2,
              <School sx={{ color: "white" }} />,
              "Cours SDI"
            )
          : null}
        {props.theoryUrl != undefined
          ? renderIconButton(
              props.theoryUrl,
              <Description sx={{ color: "white" }} />,
              "Mémo"
            )
          : null}
      </Box>
    </Box>
  );
}
