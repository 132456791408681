import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";

type PhoneComponentProps = {
  phonenumber: string;
  setPhonenumber: Dispatch<SetStateAction<string>>;
};

export default function PhoneComponent(props: PhoneComponentProps) {
  const handlePhoneChange = (event: any) => {
    const inputValue = event.target.value.replace(/[^\d+]/g, "");
    props.setPhonenumber(inputValue);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      <TextField
        label="+262....."
        variant="standard"
        sx={{ margin: "10px", width: "90%" }}
        value={props.phonenumber == undefined ? "" : props.phonenumber}
        onChange={handlePhoneChange}
        inputProps={{ pattern: "[0-9]*" }}
      />
    </Box>
  );
}
