import { CircularProgress, Tooltip } from "@mui/material";
import {
  SAddPhoto,
  SAvatar,
  SPhoto,
  SPhotoContainer,
} from "../../../styles/StyledMUI";
import { Box } from "@mui/system";
import React, { Dispatch, SetStateAction } from "react";
import { resizeFile } from "../../../utils/fileSaver";

const empty = require("../../../styles/images/noimage.png");

type PhotoComponentProps = {
  photo: string | Blob | File | ProgressEvent<FileReader> | undefined;
  setPhoto: Dispatch<
    SetStateAction<string | Blob | File | ProgressEvent<FileReader> | undefined>
  >;
};

export default function PhotoComponent(props: PhotoComponentProps) {
  const [isLoadingP, setIsLoadingP] = React.useState<boolean>(false);

  const uploadImage = async (event: any) => {
    setIsLoadingP(true);
    const file = event.target.files[0];
    const base64 = await resizeFile(file);

    props.setPhoto(base64);
    setIsLoadingP(false);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        fontFamily: '"Roboto", sans-serif',
        "& .MuiInputLabel-root": {
          color: "black",
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
      }}
    >
      {!isLoadingP ? (
        <SPhotoContainer sx={{ margin: "auto" }}>
          <SPhoto
            src={props.photo !== undefined ? props.photo : empty}
            alt="photo"
          />
          <label>
            <input
              style={{ display: "none" }}
              type="file"
              onChange={uploadImage}
              accept="image/*"
            />
            <Tooltip title="Ajouter photo">
              <SAvatar
                sx={{
                  position: "absolute",
                  bottom: "5%",
                  right: 0,
                  backgroundColor: "blue",
                }}
              >
                <SAddPhoto />
              </SAvatar>
            </Tooltip>
          </label>
        </SPhotoContainer>
      ) : (
        <CircularProgress
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </Box>
  );
}
