import {
  Badge,
  IconButton,
  Input,
  InputAdornment,
  Link,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Training, ZekayCustomer } from "../../../../api/zekay/Zekay.typed";
import {
  SAvatar,
  SBackground,
  SDate,
  SFile,
  SInfo,
  SNotes,
  SOpenProfile,
  SRedirect,
  STypography,
  STypographyItem,
} from "../../../../styles/StyledMUI";
import useZekay from "../../../../contexts/controllers/useZekay";
import { useAlert } from "../../../../hooks/useAlert";
import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/system";
import CommentsComponent from "../../../common/CommentsComponent";
import { formatDate } from "../../../../utils/dateTime";
import useAuth from "../../../../contexts/controllers/useAuth";
import ProfileForm from "../../../training/profiles/ProfileForm";
import DatesComponentReadOnly from "../../../common/DatesComponentReadOnly";
import dayjs from "dayjs";
import DocumentsComponent from "../../../common/DocumentsComponent";

type TrainingCancelledItemProps = {
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
};

export default function TrainingCancelledItem(props: TrainingCancelledItemProps) {
  const { getCustomer, getTrainingsByCreditId, updateTraining } = useZekay();
  const { showAlert } = useAlert();
  const { user } = useAuth();
  const [profileFormOpen, setProfileFormOpen] = React.useState<boolean>(false);
  const [customerUpdated, setCustomerUpdated] = React.useState<ZekayCustomer>();
  const [customer, setCustomer] = React.useState<ZekayCustomer>();
  const [comments, setComments] = React.useState<string | undefined>(
    props.training?.Comments
  );

  const [isOpenComments, setIsOpenComments] = React.useState<boolean>(false);
  const [isOpenDocuments, setIsOpenDocuments] = React.useState<boolean>(false);
  const [isOpenDates, setIsOpenDates] = React.useState<boolean>(false);

  const handleUpdate = () => {
    const tr: Training = {
      ...props.training,
      Comments: comments,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(tr)
      .then(() => getTrainingsByCreditId(String(props.training?.CreditId)))
      .then((ts) => props.setTrainings(ts))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  };

  const handleClickOpenProfile = () => {
    getCustomer(String(customer?.Id)).then((r) => setCustomerUpdated(r));
    setProfileFormOpen(true);
  };

  React.useEffect(() => {
    getCustomer(String(props.training?.CustomerID)).then(
      (c: ZekayCustomer | undefined) => setCustomer(c)
    );
  }, []);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.FirstName}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.LastName}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.Agency}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <STypographyItem>{props.training?.Qualification}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <Badge
            color="secondary"
            badgeContent={comments && comments?.length > 0 ? 1 : 0}
            sx={{ zIndex: "0" }}
          >
            <IconButton onClick={() => setIsOpenComments(!isOpenComments)}>
              <SNotes />
            </IconButton>
          </Badge>
        </TableCell>
        <TableCell sx={{ width: "5%" }} padding={"normal"}>
          {props.training.NeedsTheory ? (
            <IconButton onClick={() => setIsOpenDates(!isOpenDates)}>
              <SDate />
            </IconButton>
          ) : (
            <STypographyItem>N/A</STypographyItem>
          )}
        </TableCell>
        <TableCell sx={{ width: "5%" }} padding={"normal"}>
          <IconButton onClick={() => setIsOpenDocuments(!isOpenDocuments)}>
            <SFile />
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: "10%" }} padding={"normal"}>
          <Box display="flex">
            <Input
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              value={
                props.training?.TestResult !== undefined
                  ? props.training.TestResult
                  : ""
              }
              readOnly
            />
          </Box>
        </TableCell>
        <TableCell sx={{ width: "5%" }} padding={"normal"}>
          <SAvatar>
            <SOpenProfile onClick={handleClickOpenProfile} />
          </SAvatar>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <Link
            href={
              "https://app.zuurit.com/customers/" + props.training?.CustomerID
            }
            target="_blank"
            rel="noopener"
          >
            <SAvatar>
              <SRedirect />
            </SAvatar>
          </Link>
        </TableCell>
        <TableCell sx={{ width: "5%" }} align="left">
          <Tooltip
            title={
              <>
                <STypography>
                  Créé à :{" "}
                  {props.training?.CreatedAt !== undefined
                    ? formatDate(props.training.CreatedAt)
                    : "-"}
                </STypography>
                <STypography>
                  Mis à jour à :{" "}
                  {props.training?.UpdatedAt !== undefined
                    ? formatDate(props.training.UpdatedAt)
                    : "-"}
                </STypography>
                <STypography>
                  Mis à jour par : {props.training?.UpdatedBy}
                </STypography>
              </>
            }
          >
            <SInfo />
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <CommentsComponent
          comments={comments}
          setComments={setComments}
          handleUpdate={handleUpdate}
          isOpenComments={isOpenComments}
          setIsOpenComments={setIsOpenComments}
        />
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            margin: "auto",
            border: "none",
          }}
          colSpan={10}
        >
          {profileFormOpen ? (
            <SBackground>
              <ProfileForm
                customer={
                  customerUpdated !== undefined ? customerUpdated : customer
                }
                setProfileFormOpen={setProfileFormOpen}
                setCustomerUpdated={setCustomerUpdated}
              />
            </SBackground>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <DocumentsComponent
          training={props.training}
          setTrainings={props.setTrainings}
          refreshTrainings={() =>
            getTrainingsByCreditId(String(props.training.CreditId))
          }
          isOpenDocuments={isOpenDocuments}
        />
      </TableRow>
      <TableRow>
        <DatesComponentReadOnly
          training={props.training}
          theory={props.training.Theory ? dayjs(props.training.Theory) : null}
          rifap={
            props.training.RifapDate ? dayjs(props.training.RifapDate) : null
          }
          isOpenDates={isOpenDates}
        />
      </TableRow>
    </React.Fragment>
  );
}
