import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { STypography } from "../../../styles/StyledMUI";

export default function TrainingEmptyItem() {
  return (
    <React.Fragment>
      <TableRow component="tr">
        <TableCell align="left">
          <STypography>Aucune formation en cours</STypography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
