import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SchoolIcon from "@mui/icons-material/School";
import AssignmentIcon from "@mui/icons-material/Assignment";
import useAuth from "../../contexts/controllers/useAuth";
import { AttachMoney, Settings } from "@mui/icons-material";

const Tile = styled(Card)(({ theme }) => ({
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[700],
  },
  height: "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const MenuPage: React.FC = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const handleTileClick = (path: string) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={6} sm={3} md={2}>
          <Tile onClick={() => handleTileClick("/training")}>
            <IconButton color="primary">
              <SchoolIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <CardContent>
              <Typography variant="h5">Formations</Typography>
            </CardContent>
          </Tile>
        </Grid>
        {isAdmin() ? (
          <Grid item xs={6} sm={3} md={2}>
            <Tile onClick={() => handleTileClick("/office")}>
              <IconButton color="primary">
                <AssignmentIcon sx={{ fontSize: 50 }} />
              </IconButton>
              <CardContent>
                <Typography variant="h5">Administratif</Typography>
              </CardContent>
            </Tile>
          </Grid>
        ) : null}
        {isAdmin() ? (
          <Grid item xs={6} sm={3} md={2}>
            <Tile onClick={() => handleTileClick("/finance")}>
              <IconButton color="primary">
                <AttachMoney sx={{ fontSize: 50 }} />
              </IconButton>
              <CardContent>
                <Typography variant="h5">Comptabilité</Typography>
              </CardContent>
            </Tile>
          </Grid>
        ) : null}
        {isAdmin() ? (
          <Grid item xs={6} sm={3} md={2}>
            <Tile onClick={() => handleTileClick("/settings")}>
              <IconButton color="primary">
                <Settings sx={{ fontSize: 50 }} />
              </IconButton>
              <CardContent>
                <Typography variant="h5">Paramètres</Typography>
              </CardContent>
            </Tile>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default MenuPage;
