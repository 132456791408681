import React from "react";
import { User } from "../../../../api/auth/Auth.typed";
import { TableCell, TableRow } from "@mui/material";
import { STypographyItem } from "../../../../styles/StyledMUI";

type CardValidationItemProps = {
  user: User | undefined;
};

export default function UserItem(props: CardValidationItemProps) {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ width: "5em" }} align="left">
          <STypographyItem>{props.user?.Name}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5em" }} align="left">
          <STypographyItem>{props.user?.Email}</STypographyItem>
        </TableCell>
        <TableCell sx={{ width: "5em" }} align="left">
          <STypographyItem>{props.user?.Role}</STypographyItem>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
