import { TableCell, TableRow } from "@mui/material";
import React from "react";

export default function TrainingEmptyList() {
  return (
    <React.Fragment>
      <TableRow component="tr">
        <TableCell sx={{ width: "25%" }} align="left">
          Aucun résultat
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
